import Script from 'next/script'
import useCurrentUserCache from 'components/business/hooks/useCurrentUserCache'

type Props = {
  id?: string
}

const GA = ({ id }: Props) => {
  const { user } = useCurrentUserCache()
  const gaId = id || process.env.NEXT_PUBLIC_GA_ID_FOR_SITE
  if (!gaId) {
    return null
  }

  const uid = user?.id
  return (
    <div className="container">
      <Script src={`https://www.googletagmanager.com/gtag/js?id=${gaId}`} strategy="afterInteractive" />
      <Script id="google-analytics" strategy="afterInteractive">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){window.dataLayer.push(arguments);}
          gtag('js', new Date());
          if ('${uid}' === 'undefined') {
            gtag('config', '${id}');
          } else {
            gtag('config', '${id}', {
              user_id: '${uid}',
            });
          }
        `}
      </Script>
    </div>
  )
}

export default GA
