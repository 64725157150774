import React, { FC, useState } from 'react'
import Head from 'next/head'
import { Center, useMantineTheme } from '@mantine/core'
import { AccountMismatch } from 'components/business/Error'
import { useRouter } from 'next/router'
import { useApplicationRoleQuery } from 'types/domain'
import useTranslation from 'next-translate/useTranslation'
import { getBackgroundColor } from 'config/themeOverride/getColor'

const ForbiddenPage: FC = () => {
  const router = useRouter()
  const { t } = useTranslation('common')
  const appId = router.query.appId as string
  const [targetAccountId, setTargetAccountId] = useState<string | undefined>()
  const theme = useMantineTheme()
  const { loading } = useApplicationRoleQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      id: appId,
    },
    onError(error) {
      if (error.message === 'error.account_mismatch') {
        const { targetId } = error.graphQLErrors[0].extensions.details as { targetId?: string }
        setTargetAccountId(targetId)
      }
    },
  })

  if (loading) {
    return null
  }

  return (
    <>
      <Head>
        <title>{`${t('tip.accountMismatch.title')} - ${t('howxm.title')}`}</title>
        <style>
          {`body {
            margin: 0;
          }`}
        </style>
      </Head>
      <Center
        className="w-full h-screen"
        style={{
          backgroundColor: getBackgroundColor('gray.0', theme),
        }}
      >
        <AccountMismatch targetAccountId={targetAccountId!} />
      </Center>
    </>
  )
}

export default ForbiddenPage
