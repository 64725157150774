import React, { FC } from 'react'
import { NextSeo } from 'next-seo'
import useTranslation from 'next-translate/useTranslation'

type Props = {
  title: string
  keywords?: string
  description?: string
}

const AppSEO: FC<Props> = ({ title, keywords, description }) => {
  const { t } = useTranslation('common')
  return (
    <>
      <NextSeo
        additionalMetaTags={[
          {
            name: 'keywords',
            content: keywords ?? t('howxm.keywords'),
          },
          {
            name: 'description',
            content: description ?? t('howxm.description'),
          },
        ]}
        openGraph={{
          title: title,
          siteName: t('howxm.title'),
          description: description ?? t('howxm.description'),
          type: 'object',
          locale: 'zh-CN',
          url: 'https://howxm.com',
          images: [
            {
              url: '/logo.png',
              width: 301,
              height: 301,
              alt: title,
            },
          ],
        }}
      />
    </>
  )
}
export default AppSEO
