import React from 'react'
import Head from 'next/head'

const ResponsiveHead = () => (
  <Head>
    <meta name="viewport" content="width=device-width, initial-scale=1"></meta>
    <style>{`
          body {
            min-width: 0 !important;
          }
        `}</style>
  </Head>
)
export default ResponsiveHead
