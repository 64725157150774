import { codePrefix } from 'config'
import { groupBy } from 'lodash-es'
import { Field, FormFieldType } from 'types/domain'

const numberType = 'number'
const stringType = 'string'
const jsonType = 'JSON'

const fieldDataType: Record<FormFieldType, string> = {
  [FormFieldType.NpsField]: numberType,
  [FormFieldType.WelcomeField]: stringType,
  [FormFieldType.CheckBox]: stringType,
  [FormFieldType.CsatField]: numberType,
  [FormFieldType.CesField]: numberType,
  [FormFieldType.RadioButton]: stringType,
  [FormFieldType.TextArea]: jsonType,
  [FormFieldType.TextField]: stringType,
  [FormFieldType.RatingField]: numberType,
  [FormFieldType.CtaField]: stringType,
  [FormFieldType.DescriptionField]: stringType,
  [FormFieldType.EndingField]: stringType,
  [FormFieldType.ScreenshotField]: stringType,
}

const entryReservedFields = [
  'serial_number',
  'created_at',
  'filling_duration',
  'ip',
  'os',
  'platform',
  'browser',
  'region',
  'referer',
  'triggerred_by_event',
  'delivery_method',
  'system_link',
  'submitted_on',
  'fields',
  'customer',
]

const getFieldCategory = (fieldCode: string) => {
  if (isCustomerField(fieldCode)) {
    return 'customerFields'
  }
  if (isSceneField(fieldCode)) {
    return 'sceneFields'
  }
  if (isFormField(fieldCode)) {
    return 'formFields'
  }
}

export const groupFields = (fields: any[]) => {
  const { formFields, customerFields, sceneFields } = groupBy(fields, (field) => getFieldCategory(field.code))
  return {
    customerFields: customerFields ?? [],
    sceneFields: sceneFields ?? [],
    formFields: formFields ?? [],
  }
}

export const detectGuideField = (field: Field) => {
  return field
    ? [FormFieldType.WelcomeField, FormFieldType.EndingField, FormFieldType.CtaField].includes(
        field.type as FormFieldType
      )
    : false
}

export const notSupportFieldRulesAndFieldIndex = (field: Field) => {
  return field ? [FormFieldType.WelcomeField, FormFieldType.EndingField].includes(field.type as FormFieldType) : false
}

export const shouldShowFieldInEntryModal = (field: Field) => {
  return field ? [FormFieldType.WelcomeField, FormFieldType.EndingField].includes(field.type as FormFieldType) : false
}

export const canGroupDrag = (field: Field) => {
  return ![FormFieldType.WelcomeField].includes(field.type as FormFieldType)
}

const datetimeSuffix = '_at'
export const isDatetimeField = (fieldName?: string): boolean | undefined => fieldName?.endsWith(datetimeSuffix)

export const isFormField = (fieldCode: string) => fieldCode.startsWith(codePrefix.field)

export function isCustomerField(fieldCode: string) {
  return fieldCode.startsWith(codePrefix.customer)
}

export function isSceneField(fieldCode: string) {
  return fieldCode.startsWith(codePrefix.scene)
}

export const isExtraField = (fieldCode: string) => isCustomerField(fieldCode) || isSceneField(fieldCode)
export const isEntryReservedSystemField = (fieldCode: string) => entryReservedFields.includes(fieldCode)

export const getFieldDataType = (type: FormFieldType) => fieldDataType[type]

export const isReservedField = (fieldCode: string) =>
  isEntryReservedSystemField(fieldCode) || isExtraField(fieldCode) || isFormField(fieldCode)
