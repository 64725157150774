import { Anchor, AnchorProps } from '@mantine/core'
import { useMounted } from 'hooks'

type Props = AnchorProps & {
  content: string
  fileName: string
}

const DownloadLink = ({ fileName, content, ...anchorProps }: Props) => {
  const mounted = useMounted()
  if (!mounted) {
    return null
  }

  const handleDownload = () => {
    const blob = new Blob([content], { type: 'text/plain' })
    const url = URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.download = fileName
    link.href = url
    link.click()
    URL.revokeObjectURL(url)
  }
  return <Anchor {...anchorProps} onClick={handleDownload} />
}

export default DownloadLink
