export const APP_NAME = ''

export const apiServer = {
  graphqlUrl: process.env.NEXT_PUBLIC_SYSTEM_GRAPHQL_URL,
  publicGraphqlUrl: process.env.NEXT_PUBLIC_GRAPHQL_URL,
  bannerGraphqlUrl: process.env.NEXT_PUBLIC_BANNER_GRAPHQL_URL,
  graphqlSubscriptionUrl: process.env.NEXT_PUBLIC_GRAPHQL_SUBSCRIPTION_URL,
}

export const sdkDistributionConfig = {
  url: process.env.NEXT_PUBLIC_DISTRIBUTION_SDK_URL,
}

export const codePrefix = {
  col: 'col_',
  customer: 'customer_',
  scene: 'scene_',
  field: 'field_',
}

export const SiteHowXMAppId = process.env.NEXT_PUBLIC_SITE_HOWXM_SDK_APPID
export const HowXMAppId = process.env.NEXT_PUBLIC_HOWXM_SDK_APPID
export const BaiduAnalyticsId = process.env.NEXT_PUBLIC_SITE_BAIDU_ANALYTICS_APPID

export const fieldDefaultImage = {
  width: 768,
  height: 256,
  storageKey: process.env.NEXT_PUBLIC_DEFAULT_FIELD_IMAGE_STORAGE_KEY,
  url: process.env.NEXT_PUBLIC_DEFAULT_FIELD_IMAGE_URL,
}

export * from './constants/helpLink'
