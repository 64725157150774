import { PublicPaths } from 'config/constants'
import React, { FC, PropsWithChildren, useEffect } from 'react'
import { useRouter } from 'next/router'
import { closeAllModals, ModalsProvider } from '@mantine/modals'
import useTranslation from 'next-translate/useTranslation'
import { Notifications } from '@mantine/notifications'
import GA from 'components/ui/GA'
import AppSEO from 'components/ui/SEO'
import dynamic from 'next/dynamic'
import useServerSideErrors from './hooks/useServerSideErrors'
import ClientSideOnly from 'components/ui/ClientSideOnly'

const PermissionsProvider = dynamic(() => import('components/business/PermissionsProvider'))

type Props = PropsWithChildren & {
  pageTitle: string
  pageKeywords?: string
  pageDescription?: string
  error?: string
}

const XmLayout: FC<Props> = ({ pageTitle, pageKeywords, pageDescription, error, children }) => {
  const router = useRouter()
  const { t } = useTranslation('common')
  useServerSideErrors(error)
  const isPublicPath = PublicPaths.includes(router.pathname)

  useEffect(() => {
    router.events.on('routeChangeComplete', closeAllModals)
    return () => {
      router.events.off('routeChangeComplete', closeAllModals)
    }
  }, [router])

  if (error) {
    return null
  }

  return (
    <>
      <ClientSideOnly>
        <GA />
      </ClientSideOnly>
      <AppSEO title={pageTitle} keywords={pageKeywords} description={pageDescription} />
      <Notifications position="top-center" />
      <ModalsProvider labels={{ confirm: t('actions.confirm'), cancel: t('actions.cancel') }}>
        {isPublicPath ? <>{children}</> : <PermissionsProvider>{children}</PermissionsProvider>}
      </ModalsProvider>
    </>
  )
}

export default XmLayout
