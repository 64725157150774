import React, { FC } from 'react'
import { MantineSize, Select } from '@mantine/core'
import { Protocol } from 'config/constants/link'

interface Props {
  onChange: (value: string) => void
  value: Protocol
  size?: MantineSize
}

const widthMap: Record<MantineSize, string> = {
  xs: '90px',
  sm: '98px',
  md: '112px',
  lg: '120px',
  xl: '130px',
}

const ProtocolSelector: FC<Props> = ({ onChange, value, size }) => {
  const options = Object.values(Protocol).map((protocol) => ({
    label: protocol,
    value: protocol,
  }))
  return (
    <Select
      size={size}
      value={value}
      data={options}
      onChange={onChange}
      styles={(theme) => ({
        root: {
          width: widthMap[size || 'sm'],
          marginRight: '-1px',
          '&:focus-within': {
            zIndex: 1,
          },
        },
        input: {
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0,
        },
      })}
    />
  )
}

export default ProtocolSelector
