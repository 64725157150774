import React, { ReactNode } from 'react'
import { Group, Text, useMantineTheme } from '@mantine/core'
import { getTextColor } from 'config/themeOverride/getColor'

type Props = {
  label: string
  labelClassName?: string
  value?: number | ReactNode
}

const LabelWithValue = ({ label, value, labelClassName }: Props) => {
  const theme = useMantineTheme()
  return (
    <Group spacing={0} noWrap>
      <Text className={`block whitespace-nowrap ${labelClassName}`} lineClamp={1} color={getTextColor('gray.7', theme)}>
        {label}
      </Text>
      ：
      <Text className="block break-all" lineClamp={1}>
        {value}
      </Text>
    </Group>
  )
}

export default LabelWithValue
