import { ReactNode } from 'react'
import { Group } from '@mantine/core'
import { useId } from '@mantine/hooks'
import { useStyles } from 'components/ui/RadioGroup/styles'

interface RadioGroupItem {
  label: ReactNode
  value: string
}

interface RadioGroupProps {
  data: RadioGroupItem[]
  value?: string
  onChange?: (value: string) => void
}

const RadioGroup = ({ data, value, onChange }: RadioGroupProps) => {
  const uuid = useId()
  const { classes } = useStyles()

  const options = data.map((item) => {
    const isActive = item.value === value
    return (
      <label key={item.value} className={classes.option} data-active={isActive || undefined}>
        <input
          type="radio"
          className="hidden"
          name={uuid}
          id={`${uuid}-${item.value}`}
          value={value}
          checked={isActive}
          onChange={() => onChange?.(item.value)}
        />
        {item.label}
      </label>
    )
  })

  return (
    <Group spacing={0} grow className={classes.root}>
      {options}
    </Group>
  )
}

export default RadioGroup
