import { CSSProperties, PropsWithChildren, ReactNode } from 'react'
import { Stack, Title } from '@mantine/core'
import { getDefaultBorderColor } from 'config/themeOverride/getColor'

type Props = {
  title?: ReactNode
  spacing?: number
  px?: number
  py?: number
  style?: CSSProperties
  className?: string
}

const Panel = ({ title, spacing = 16, px = 24, py = 16, style, className, children }: PropsWithChildren<Props>) => {
  const stackProps = {
    px,
    py,
    spacing,
    className,
  }
  return (
    <Stack
      {...stackProps}
      style={style}
      sx={(theme) => {
        const border = `1px solid ${getDefaultBorderColor(theme)}`
        return {
          borderLeft: border,
          borderRight: border,
        }
      }}
    >
      {title && <Title order={6}>{title}</Title>}
      {children}
    </Stack>
  )
}

export default Panel
