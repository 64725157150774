import React, { FC, ReactNode } from 'react'
import { Flex, Input, MantineSize, TextInput } from '@mantine/core'
import ProtocolSelector from './ProtocolSelector'
import { isPrefixWithProtocol, parse, stringify } from './utils'
import update from 'immutability-helper'
import { Protocol } from 'config/constants/link'

interface Props {
  label?: ReactNode
  value: string
  onChange: (value: string) => void
  error: ReactNode
  size?: MantineSize
  placeholder?: string
}

export interface UrlObject {
  protocol: Protocol
  other: string
}

const UrlInputWithProtocol: FC<Props> = ({ label, size, onChange, value, placeholder, error }) => {
  const url = parse(value)
  const handleInputChange = (inputValue: string) => {
    onChange?.(stringify(update(url, { other: { $set: inputValue } })))
  }

  const handleInputBlur = (inputValue: string) => {
    if (isPrefixWithProtocol(inputValue)) {
      onChange?.(inputValue)
    }
  }

  const handleProtocolChange = (value: string) => {
    onChange?.(stringify(update(url, { protocol: { $set: value as Protocol } })))
  }

  return (
    <Input.Wrapper label={label}>
      <Flex>
        <ProtocolSelector onChange={handleProtocolChange} value={url.protocol} size={size} />
        <TextInput
          size={size}
          value={url.other}
          placeholder={placeholder}
          onChange={(e) => handleInputChange(e.target.value.trim())}
          onBlur={(e) => handleInputBlur(e.target.value.trim())}
          error={error}
          className="grow"
          styles={{
            input: { borderTopLeftRadius: 0, borderBottomLeftRadius: 0 },
          }}
        />
      </Flex>
    </Input.Wrapper>
  )
}

export default UrlInputWithProtocol
