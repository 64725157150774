import React, { KeyboardEventHandler, ReactElement, useState } from 'react'
import { Button, Group, Text } from '@mantine/core'
import useTranslation from 'next-translate/useTranslation'
import { isEnter } from 'utils/keyboardUtils'
import { useDisclosure } from '@mantine/hooks'
import { InlineInput } from 'components/ui'

interface Props {
  defaultValue: string
  onSave: (
    value: string,
    handlers: { readonly open: () => void; readonly close: () => void; readonly toggle: () => void }
  ) => void
  loading: boolean
  width?: number
  maxLength?: number
  displayComponent?: ReactElement
  editable: boolean
}

const InlineEditableText = ({ defaultValue, onSave, loading, width, maxLength, displayComponent, editable }: Props) => {
  const [opened, handlers] = useDisclosure(false)
  const [value, setValue] = useState(defaultValue)
  const { t } = useTranslation('common')
  const error = value.trim().length === 0

  const handleChange = (value: string) => {
    setValue(value)
  }

  const handleCancel = () => {
    setValue(defaultValue)
    handlers.close()
  }

  const onKeyDown: KeyboardEventHandler<HTMLSpanElement> = (e) => {
    if (isEnter(e) && !error) {
      handleSave()
    }
  }

  const handleSave = () => {
    onSave(value.trim(), handlers)
    setValue(value.trim())
  }

  return opened ? (
    <Group align="top" spacing="sm">
      <InlineInput
        maxLength={maxLength}
        value={value}
        onChange={handleChange as never}
        onKeyDown={onKeyDown}
        maxWidth={width}
        minWidth={width}
        autoRestore
      />
      <Button onClick={handleSave} loading={loading} disabled={error}>
        {t('actions.save')}
      </Button>
      <Button variant="default" onClick={handleCancel}>
        {t('actions.cancel')}
      </Button>
    </Group>
  ) : (
    <Group spacing="sm" h={36}>
      {displayComponent || <Text>{value}</Text>}
      {editable && (
        <Button variant="subtle" onClick={() => handlers.open()}>
          {t('actions.modify')}
        </Button>
      )}
    </Group>
  )
}

export default InlineEditableText
