import { Box } from '@mantine/core'
import { useIntersection } from '@mantine/hooks'
import { ReactNode } from 'react'

type Props = {
  className?: string
  children: (entry: IntersectionObserverEntry) => ReactNode
}

const ContentIntoView = ({
  className,
  children,
  ...intersectionObserverOptions
}: Props & ConstructorParameters<typeof IntersectionObserver>[1]) => {
  const { ref, entry } = useIntersection(intersectionObserverOptions)
  return (
    <Box className={className} ref={ref}>
      {children(entry)}
    </Box>
  )
}

export default ContentIntoView
