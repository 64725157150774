import React from 'react'
import { openConfirmModal } from '@mantine/modals'
import { Center, Text, Title } from '@mantine/core'
import { IconAlertCircle } from '@tabler/icons-react'
import { OpenConfirmModal } from '@mantine/modals/lib/context'
import { castArray } from 'lodash-es'

type Props = OpenConfirmModal & {
  title: string
  onConfirm(): void
  content?: string | string[]
  onClose?(): void
  hideIcon?: boolean
}

const openAlertModal = ({ title, content, onClose, onConfirm, hideIcon, ...rest }: Props) => {
  return openConfirmModal({
    title: (
      <Center>
        {hideIcon ? (
          <Title order={5}>{title}</Title>
        ) : (
          <>
            <Text color="red.6" className="flex" mr="xs">
              <IconAlertCircle />
            </Text>
            <Text size="md">{title}</Text>
          </>
        )}
      </Center>
    ),
    children: content && (
      <>
        {castArray(content).map((item, idx) => (
          <Text key={idx} size="sm">
            {item}
          </Text>
        ))}
      </>
    ),
    onConfirm,
    onClose: () => onClose?.(),
    radius: 'md',
    ...rest,
  })
}

export default openAlertModal
