import React, { FC } from 'react'
import { Center, useMantineTheme } from '@mantine/core'
import { ServerError } from 'components/business/Error'
import Head from 'next/head'
import { ErrorComponentProps } from 'components/business/GlobalErrorBoundary'
import { getBackgroundColor } from 'config/themeOverride/getColor'

const ServerErrorPage: FC<ErrorComponentProps> = () => {
  const theme = useMantineTheme()
  return (
    <>
      <Head>
        <title>500 Internal Server Error</title>
      </Head>
      <Center
        className="w-full h-screen"
        style={{
          backgroundColor: getBackgroundColor('gray.0', theme),
        }}
      >
        <ServerError />
      </Center>
    </>
  )
}

export default ServerErrorPage
