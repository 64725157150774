import { HX_ACCOUNT_REFERER, HX_UTM_MEDIUM, HX_UTM_SOURCE } from 'config/constants/cookie'
import { ServerResponse } from 'http'

export function readJson(cookieName: string, defaultValue: any): any {
  const cookieValue = read(cookieName)
  return cookieValue ? JSON.parse(cookieValue) : defaultValue
}

export function readString(cookieName: string): string | undefined {
  return read(cookieName)
}

export function read(name: string, customCookie?: string): any {
  if (typeof window === 'undefined' && !customCookie) {
    return undefined
  }

  const result = new RegExp('(?:^|; )' + encodeURIComponent(name) + '=([^;]*)').exec(customCookie ?? document.cookie)
  return result ? result[1] : undefined
}

interface WriteCookieProps {
  name: string
  value: string
  days?: number
  path?: string
  domain?: string
}

export function writeIfAbsent({ name, value, days, path, domain }: WriteCookieProps): void {
  if (readString(name)) {
    return
  }
  write({ name, value, days, path, domain })
}

export function write({ name, value, days, path, domain }: WriteCookieProps): void {
  if (typeof window === 'undefined') {
    return
  }

  const expires = days ? '; expires=' + getExpireTime(days).toUTCString() : ''
  const domainStr = domain ? `; domain=${domain}` : ''
  document.cookie = name + '=' + value + expires + `; path=${path || '/'}` + domainStr
}

type RemoveCookieProps = Omit<WriteCookieProps, 'value' | 'days'>

export function remove({ name, path, domain }: RemoveCookieProps) {
  write({ name, value: '', days: -1, path, domain })
}

export function clearAllCookie() {
  const keys = document.cookie.match(/[^ =;]+(?==)/g)
  if (keys) {
    for (let i = 0; i < keys.length; i++) {
      remove({ name: keys[i] })
      remove({ name: keys[i], domain: process.env.NEXT_PUBLIC_COOKIE_ALL_DOMAIN })
    }
  }
}

function getExpireTime(days: number) {
  const date = new Date()
  date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
  return date
}

export function getHxUtmSource() {
  return readString(HX_UTM_SOURCE)
}

export function getHxUtmMedium() {
  return readString(HX_UTM_MEDIUM)
}

export function getHxAccountReferer() {
  return readString(HX_ACCOUNT_REFERER)
}

export function removeUserSourceTrackingCookies() {
  const allDomain = process.env.NEXT_PUBLIC_COOKIE_ALL_DOMAIN
  remove({ name: HX_UTM_SOURCE, domain: allDomain })
  remove({ name: HX_UTM_MEDIUM, domain: allDomain })
  remove({ name: HX_ACCOUNT_REFERER, domain: allDomain })
}

export const deleteCookiesInServerSide = (cookie: string, res: ServerResponse) => {
  res.setHeader('Set-Cookie', `${cookie}=;Path=/;Max-Age=0;Domain=${process.env.NEXT_PUBLIC_COOKIE_ALL_DOMAIN}`)
  return res
}

export const addCookiesInServerSide = (cookie: string, value: any, res: ServerResponse) => {
  res.setHeader(
    'Set-Cookie',
    `${cookie}=${encodeURIComponent(value)};Path=/;Domain=${process.env.NEXT_PUBLIC_COOKIE_ALL_DOMAIN}`
  )
  return res
}
