import { CampaignDistributionChannel } from 'types/domain'

export const helpCenterUrl = 'https://howxm.com/help/'
export const sdkInstallHelpLink = {
  [CampaignDistributionChannel.Web]: 'https://howxm.com/help/articles/web-sdk-intro',
  [CampaignDistributionChannel.IosSwift]: 'https://howxm.com/help/articles/ios-x-sdk',
  [CampaignDistributionChannel.Android]: 'https://howxm.com/help/articles/x-sdk-andriod',
  [CampaignDistributionChannel.WechatMiniapp]: 'https://howxm.com/help/articles/x-sdk-miniapp',
  [CampaignDistributionChannel.ReactNative]: '',
}
export const customerAttributeHelpLink = {
  [CampaignDistributionChannel.Web]: 'https://howxm.com/help/articles/web-sdk-intro#1-identify',
  [CampaignDistributionChannel.IosSwift]: 'https://howxm.com/help/articles/ios-x-sdk#1-identify',
  [CampaignDistributionChannel.Android]: 'https://howxm.com/help/articles/x-sdk-andriod#1-identify',
  [CampaignDistributionChannel.WechatMiniapp]: 'https://howxm.com/help/articles/x-sdk-miniapp#1-identify',
  [CampaignDistributionChannel.ReactNative]: '',
}
export const eventHelpLink = {
  [CampaignDistributionChannel.Web]: 'https://howxm.com/help/articles/web-sdk-intro#2-event',
  [CampaignDistributionChannel.IosSwift]: 'https://howxm.com/help/articles/ios-x-sdk#2-event',
  [CampaignDistributionChannel.Android]: 'https://howxm.com/help/articles/x-sdk-andriod#2-event',
  [CampaignDistributionChannel.WechatMiniapp]: 'https://howxm.com/help/articles/x-sdk-miniapp#2-event',
  [CampaignDistributionChannel.ReactNative]: '',
}
export const codeViewLink = {
  [CampaignDistributionChannel.Web]: 'https://howxm.com/help/articles/web-sdk-intro',
  [CampaignDistributionChannel.IosSwift]: 'https://howxm.com/help/articles/ios-x-sdk',
  [CampaignDistributionChannel.Android]: 'https://howxm.com/help/articles/x-sdk-andriod',
  [CampaignDistributionChannel.WechatMiniapp]: 'https://howxm.com/help/articles/x-sdk-miniapp',
  [CampaignDistributionChannel.ReactNative]: '',
  IOS_LANGUAGES: 'https://howxm.com/help/articles/ios-x-sdk#2-ios',
  UNI_APP: 'https://howxm.com/help/articles/x-sdk-uniapp',
  TARO: 'https://howxm.com/help/articles/x-sdk-miniapp#2-taro',
  GITHUB: 'https://github.com/howxm',
}
export const webhookHelpLink = 'https://howxm.com/help/articles/webhook'

export const distributionOpenLink = 'https://howxm.com/help/articles/distribution-strategy'
export const distributionWhenLink = 'https://howxm.com/help/articles/distribution-timing-setting'
export const distributionWhereLink = 'https://howxm.com/help/articles/distribution-page-setting'
export const distributionSettingLink = 'https://howxm.com/help/articles/distribution-settings'
export const triggerEventLink = 'https://howxm.com/help/articles/distribution-evens-trigger'
export const distributionUserLink = 'https://howxm.com/help/articles/distribution-users'

export const npmCodeViewLink = 'https://howxm.com/help/articles/npm-web-sdk-intro'
export const blogUrl = 'https://howxm.com/institute/'
export const newFeatureUrl = 'https://howxm.com/help/updates'
export const replyHelpLink = 'https://howxm.com/help/articles/user-reply'
