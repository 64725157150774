import { MantineTheme } from '@mantine/core'

type ColorMapping = { [x: string]: string }

const darkModeTextColorMapping: ColorMapping = {
  'gray.7': 'dark.2',
  'gray.8': 'dark.1',
  'gray.9': 'dark.0',
  'gray.5': 'gray.7',
  'gray.6': 'gray.1',
  'brandBlue.0': 'dark.7',
  'brandBlue.1': 'dark.1',
  'brandBlue.7': 'dark.4',
  'brandBlue.9': 'dark.1',
  'teal.6': 'teal.8',
  white: 'dark.7',
  red: 'red.8',
}

const darkModeBgColorMapping: ColorMapping = {
  'gray.0': 'dark.6',
  'gray.1': 'dark.5',
  white: 'dark.7',
  'brandBlue.0': 'dark.5',
  'brandBlue.1': 'dark.1',
  'brandBlue.7': 'dark.3',
  'orange.0': 'dark.5',
  'teal.1': 'teal.3',
  black: 'white',
}

const darkModeBorderColorMapping: ColorMapping = {
  'gray.1': 'dark.4',
  'gray.2': 'dark.5',
  'gray.3': 'dark.4',
  'brandBlue.0': 'dark.0',
  'brandBlue.2': 'dark.7',
  'brandBlue.7': 'dark.4',
}

export function pickSchemeColor(lightColor: string, theme: MantineTheme, darkColor?: string) {
  const darkMode = theme.colorScheme === 'dark'
  return darkMode ? darkColor ?? lightColor : lightColor
}

function getColorWithDarkMapping(color: string, theme: MantineTheme, darkColor?: string) {
  const colorKey = pickSchemeColor(color, theme, darkColor)
  if (colorKey === 'white') {
    return theme.white
  }
  if (colorKey === 'black') {
    return theme.black
  }
  return theme.fn.themeColor(colorKey)
}

export function getTextColor(color: string, theme: MantineTheme, darkColor?: string) {
  return getColorWithDarkMapping(color, theme, darkColor ?? darkModeTextColorMapping[color])
}

export function getBackgroundColor(color: string, theme: MantineTheme, darkColor?: string) {
  return getColorWithDarkMapping(color, theme, darkColor ?? darkModeBgColorMapping[color])
}

export function getBorderColor(color: string, theme: MantineTheme, darkColor?: string) {
  return getColorWithDarkMapping(color, theme, darkColor ?? darkModeBorderColorMapping[color])
}

export function getDefaultBorderColor(theme: MantineTheme) {
  return getBorderColor('gray.2', theme)
}

export function getDefaultTooltipBorderColor(theme: MantineTheme) {
  return getBorderColor('gray.1', theme, 'dark.3')
}
