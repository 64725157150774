import { useRef } from 'react'
import { isNil } from 'lodash-es'
import { length, substring } from 'utils'

export default function useInputCompositionProps(maxLength?: number, onChange?: (value: string) => void) {
  const inCompositionRef = useRef(false)
  const handleOnChange = (value: string) => {
    onChange?.(value)

    if (!inCompositionRef.current) {
      if (!isNil(maxLength) && length(value) >= maxLength * 2) {
        value = substring(value, maxLength * 2)
        onChange?.(value)
      }
    }
  }

  // chrome：compositionstart onChange compositionend
  // firefox：compositionstart compositionend onChange
  const handleComposition = (event: any) => {
    if (event.type === 'compositionend') {
      inCompositionRef.current = false
      if (!inCompositionRef.current) {
        handleOnChange(event?.target?.value)
      }
    } else {
      inCompositionRef.current = true
    }
  }

  return {
    handleOnChange,
    inCompositionRef,
    handleComposition,
  }
}
