import { useState } from 'react'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'
import { UrlObject } from 'url'

import { User, useSwitchAccountMutation } from 'types/domain'
import { message } from 'components/ui'
import { graphqlErrorCatch } from 'utils'

type Params = {
  skipMessage?: boolean
}

export default function useAccountSwitcher({ skipMessage = false }: Params = {}) {
  const router = useRouter()
  const { t } = useTranslation('common')
  const [switchAccount] = useSwitchAccountMutation()
  const [loading, setLoading] = useState(false)

  return async (accountId: string, redirection?: UrlObject | string, as?: UrlObject | string) => {
    if (loading) {
      return
    }
    setLoading(true)
    try {
      const { data } = await switchAccount({
        variables: { input: { accountId } },
      })
      const user = data?.switchAccount?.user as User
      const accountName = user.accountUser?.account?.name
      const switchCallback = () => {
        !skipMessage && message.successMsg(t('message.switchAccountSuccess', { name: accountName }))
      }
      if (redirection) {
        router.replace(redirection, as).then(switchCallback)
      } else {
        switchCallback()
      }
    } catch (err: any) {
      setLoading(false)
      const { graphQLErrors } = err
      graphqlErrorCatch({
        graphQLErrors,
        defaultMessage: t('message.graphqlError', { message: err.message }),
      })
    }
  }
}
