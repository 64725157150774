import React, { PropsWithChildren } from 'react'
import { Icon } from 'components/ui'
import { ActionIcon, HoverCard, Text, useMantineTheme } from '@mantine/core'
import { getTextColor } from 'config/themeOverride/getColor'

type Props = {
  width?: number
  withinPortal?: boolean
}

const HelpDocTooltip = ({ width, withinPortal = false, children }: PropsWithChildren<Props>) => {
  const theme = useMantineTheme()
  return (
    <HoverCard width={width || 320} shadow="md" position="top" arrowSize={8} withArrow withinPortal={withinPortal}>
      <HoverCard.Target>
        <ActionIcon size="sm" variant="transparent">
          <Icon name="help" style={{ color: getTextColor('gray.7', theme) }} />
        </ActionIcon>
      </HoverCard.Target>
      <HoverCard.Dropdown>
        <Text size="sm" className="leading-5">
          {children}
        </Text>
      </HoverCard.Dropdown>
    </HoverCard>
  )
}

export default HelpDocTooltip
