import { MantineTheme } from '@mantine/core'
import { getBorderColor } from 'config/themeOverride/getColor'

const outsideModalBodyPaddingY = 48
// modal inner paddingY height(48*2) + modal header height(45)
export const outsideModalBodyHeight = 141

export const modalConfig = (theme: MantineTheme) => {
  return {
    centered: true,
    padding: 0,
    size: 1000,
    styles: {
      inner: {
        padding: `${outsideModalBodyPaddingY}px !important`,
      },
      title: {
        width: '100%',
        marginRight: 'var(--mantine-spacing-md)',
      },
      content: {
        maxHeight: `calc(100vh - ${outsideModalBodyPaddingY * 2}px) !important`,
      },
      header: {
        margin: 0,
        padding: 'var(--mantine-spacing-sm) var(--mantine-spacing-xl)',
        borderBottom: `1px solid ${getBorderColor('gray.2', theme)}`,
      },
    },
  }
}
