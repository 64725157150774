import { useEffect, useState } from 'react'
import { initGeetest } from 'components/ui/Geetest'

const useGeetest = (onSuccess: (captcha: any) => void, response: any) => {
  const [captcha, setCaptcha] = useState<any>(null)
  const configuration = response.data?.geetestConfiguration || null

  useEffect(() => {
    if (configuration?.enabled) {
      initGeetest(
        configuration.captchaId,
        (captcha) => {
          setCaptcha(captcha)
        },
        (captcha) => {
          onSuccess(captcha)
        }
      )
    }
    // eslint-disable-next-line
  }, [configuration])

  return [captcha, configuration]
}

export default useGeetest
