import { Duration } from 'duration-fns'
import { UNITS_META_MAP } from 'duration-fns/dist/lib/units'
import { isNil, parseInt } from 'lodash-es'
import { Translate } from 'next-translate'

const totalSeconds = 60 * 60 * 24

export function formatTimeDuration(t: Translate, seconds: number | null | undefined) {
  if (isNil(seconds)) {
    return ''
  }
  const days = parseInt(String(seconds / totalSeconds), 10)
  const hours = parseInt(String((seconds % totalSeconds) / (60 * 60)), 10)
  const minutes = parseInt(String((seconds % (60 * 60)) / 60), 10)
  const _seconds = seconds % 60

  if (days > 0) {
    return t('entry:filledDuration.days', { days, hours, minutes, seconds: _seconds })
  }
  if (hours > 0) {
    return t('entry:filledDuration.hours', { hours, minutes, seconds: _seconds })
  }
  if (minutes > 0) {
    return t('entry:filledDuration.minutes', { minutes, seconds: _seconds })
  }
  return t('entry:filledDuration.seconds', { seconds: _seconds })
}

export function formatSimpleTimeDuration(seconds?: number | null) {
  if (isNil(seconds)) {
    return '00:00:00'
  }
  const hours = parseInt(String((seconds % totalSeconds) / (60 * 60)), 10)
  const minutes = parseInt(String((seconds % (60 * 60)) / 60), 10)
  const _seconds = seconds % 60

  return (
    hours.toString().padStart(2, '0') +
    ':' +
    minutes.toString().padStart(2, '0') +
    ':' +
    _seconds.toString().padStart(2, '0')
  )
}

export function calcDurationUnit(
  supportUnits: Partial<keyof typeof UNITS_META_MAP>[],
  value?: Duration | null
): keyof typeof UNITS_META_MAP {
  if (isNil(value)) {
    return supportUnits[0]
  }

  const units = supportUnits
    .map((unit) => {
      if (value[unit] !== 0) {
        return unit
      }
    })
    .filter((unit) => unit)
  return units[0] ?? supportUnits[0]
}
