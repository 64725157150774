import { MantineThemeOverride } from '@mantine/core'
import componentsOverride from './components'
import fontFamily from './fontFamily'

const themeOverride: MantineThemeOverride = {
  fontFamily: fontFamily,
  defaultRadius: 'sm',
  black: '#212529',
  colors: {
    brandBlue: [
      '#EDF2FC',
      '#D7E0F7',
      '#B0C2F0',
      '#8CADEE',
      '#517FD9',
      '#3662D7',
      '#305ACC',
      '#1D55C4',
      '#1B4FB5',
      '#1948A6',
    ],
  },
  headings: {
    fontFamily: fontFamily,
    fontWeight: 600,
    sizes: {
      h1: { fontSize: '32px', lineHeight: 1.3 },
      h2: { fontSize: '24px', lineHeight: 1.35 },
      h3: { fontSize: '20px', lineHeight: 1.4 },
      h4: { fontSize: '18px', lineHeight: 1.45 },
      h5: { fontSize: '16px', lineHeight: 1.5 },
      h6: { fontSize: '14px', lineHeight: 1.5 },
    },
  },
  radius: {
    xs: '4px',
    sm: '6px',
    md: '8px',
    lg: '12px',
    xl: '24px',
  },
  spacing: {
    xs: '4px',
    sm: '8px',
    md: '12px',
    lg: '16px',
    xl: '24px',
    '2xl': '32px',
    '3xl': '40px',
    '4xl': '48px',
  },
  shadows: {
    xs: '0px 8px 8px -8px rgba(0, 0, 0, 0.1)',
    sm: '0 1px 3px rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05) 0px 10px 15px -5px, rgba(0, 0, 0, 0.04) 0px 7px 7px -5px',
    md: '0 1px 3px rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px',
    lg: '0 1px 3px rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05) 0px 28px 23px -7px, rgba(0, 0, 0, 0.04) 0px 12px 12px -7px',
    xl: '0 1px 3px rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05) 0px 36px 28px -7px, rgba(0, 0, 0, 0.04) 0px 17px 17px -7px',
  },
  fontSizes: {
    xs: '12px',
    sm: '14px',
    md: '16px',
    lg: '18px',
    xl: '20px',
  },
  breakpoints: {
    xs: '576px',
    sm: '768px',
    md: '992px',
    lg: '1200px',
    xl: '1400px',
  },
  components: componentsOverride as any,
}

export default themeOverride
