import React, { PropsWithChildren, ReactNode } from 'react'
import { Image, Paper, Stack, Text } from '@mantine/core'

interface PageEmptyProps {
  image?: {
    src: string
    width: number
    caption?: string
  }
  title?: string
  description?: string | ReactNode
  children?: ReactNode | null
  width?: number | string
  height?: number | string
  withBorder?: boolean
}

function PageEmpty(props: PropsWithChildren<PageEmptyProps>) {
  const { image, title, description, children, width, height, withBorder } = props

  return (
    <Paper p="2xl" withBorder={withBorder ?? true} style={{ width: width, height: height || 'calc(100% - 44px)' }}>
      <Stack align="center" justify="center" style={{ height: '100%' }}>
        <Image alt={title ?? ''} src={image?.src} width={image?.width} caption={image?.caption} />
        {title && <Text>{title}</Text>}
        {description && (
          <Text color="dimmed" size="sm">
            {description}
          </Text>
        )}
        {children && <div>{children}</div>}
      </Stack>
    </Paper>
  )
}

export default PageEmpty
