import { HelpDocTooltip } from 'components/ui'
import useTranslation from 'next-translate/useTranslation'
import AnchorLink from 'components/ui/AnchorLink'
import { htmlEncodeByRegExp } from 'utils'
import { TypographyStylesProvider, useMantineTheme } from '@mantine/core'
import React from 'react'

type Props = {
  content: string
  width?: number
  moreLabel?: string
  moreLink?: string
}

const HelpDoc = ({ content, width, moreLink, moreLabel }: Props) => {
  const { t } = useTranslation('common')
  const helpMoreLabel = moreLabel || t('common:actions.learnMore')
  const theme = useMantineTheme()

  return (
    <HelpDocTooltip width={width} withinPortal>
      <TypographyStylesProvider
        sx={{
          fontSize: theme.fontSizes.sm,
          wordBreak: 'break-word',
        }}
      >
        <div dangerouslySetInnerHTML={{ __html: htmlEncodeByRegExp(content) }} />
      </TypographyStylesProvider>
      {moreLink && (
        <AnchorLink href={moreLink} target="_blank" passHref className="text-white underline">
          {helpMoreLabel}
        </AnchorLink>
      )}
    </HelpDocTooltip>
  )
}

export default HelpDoc
