import React, { FC } from 'react'
import { Center, useMantineTheme } from '@mantine/core'
import { NotFound } from 'components/business/Error'
import Head from 'next/head'
import { ErrorComponentProps } from 'components/business/GlobalErrorBoundary'
import { getBackgroundColor } from 'config/themeOverride/getColor'

const NotFoundPage: FC<ErrorComponentProps> = () => {
  const theme = useMantineTheme()
  return (
    <>
      <Head>
        <title>404 Not Found</title>
      </Head>
      <Center
        className="w-full h-screen"
        style={{
          backgroundColor: getBackgroundColor('gray.0', theme),
        }}
      >
        <NotFound />
      </Center>
    </>
  )
}

export default NotFoundPage
