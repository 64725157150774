import { Text, useMantineTheme } from '@mantine/core'
import { getTextColor } from 'config/themeOverride/getColor'
import React from 'react'
import { TextProps } from '@mantine/core/lib/Text/Text'

const TableHeaderCell = ({ children, className, ...rest }: TextProps) => {
  const theme = useMantineTheme()
  return (
    <Text {...rest} className={`${className} truncate`} color={getTextColor('gray.7', theme)}>
      {children}
    </Text>
  )
}

export default TableHeaderCell
