import React, { FC } from 'react'
import { useRouter } from 'next/router'
import { Image, useMantineTheme } from '@mantine/core'
import logoLightImg from './logo-light.png'
import logoDarkImg from './logo-dark.png'
import { AnchorLink } from 'components/ui'
import { ColorSchemeType } from 'components/ui/Logo/type'

interface LogoImageProps {
  colorScheme?: ColorSchemeType
  width?: number
  height?: number
}

interface LogoProps extends LogoImageProps {
  href?: string
}

const LogoImage: FC<LogoImageProps> = ({ width = 80, height = 32 }) => {
  const theme = useMantineTheme()
  return (
    <Image
      src={theme.colorScheme === 'dark' ? logoLightImg.src : logoDarkImg.src}
      alt="xm-logo"
      width={width}
      height={height}
    />
  )
}

const Logo: FC<LogoProps> = ({ href = '', ...rest }) => {
  const router = useRouter()
  const appId = router.query.appId as string

  return (
    <>
      {href ? (
        <AnchorLink href={href.replace('[appId]', appId)}>
          <LogoImage {...rest} />
        </AnchorLink>
      ) : (
        <LogoImage {...rest} />
      )}
    </>
  )
}

export default Logo
