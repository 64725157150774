interface NumberFormatOptions {
  point?: number
  compact?: boolean
  style?: string
  currency?: string
  currencyDisplay?: string
}

export function formatNumberToThousands(number: number, options?: NumberFormatOptions): string {
  const { point, compact, ...rest } = options ?? {}
  let digitParams =
    point === 0
      ? {
          maximumFractionDigits: 0,
          minimumFractionDigits: 0,
        }
      : { maximumFractionDigits: point ?? 1 }

  return new Intl.NumberFormat('zh-CN', {
    notation: compact ? 'compact' : 'standard',
    ...digitParams,
    ...rest,
  }).format(number)
}

export function formatNumberWithPrecision(number: number, digits: number = 1) {
  return Math.round(number) == number ? number : Number(number).toFixed(digits)
}
