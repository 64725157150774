import { formatNumberWithPrecision } from './numberFormat'

export function calcPercent(count: number = 0, total: number, digits: number = 1, emptyStr?: string) {
  if (total === 0) {
    return emptyStr ?? '0%'
  }
  if (count === 0) {
    return '0%'
  }
  return numberToPercent(Math.abs(count) / Math.abs(total), digits)
}

export function numberToPercent(number: number, digits: number = 1) {
  return formatNumberWithPrecision(number * 100, digits) + '%'
}

export const percent2Number = (percent: string) => {
  let str = percent.replace('%', '')
  return Number.parseFloat(str) / 100
}

export function calcPercentNoSign(count: number = 0, total: number) {
  if (total === 0 || count === 0) {
    return 0
  }
  return Math.round((Math.abs(count) / Math.abs(total)) * 10000) / 100.0
}
