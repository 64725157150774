import React, { FC } from 'react'
import { Box, Button, Center, Image, Stack, Text, Title, useMantineTheme } from '@mantine/core'
import image from 'assets/images/403.png'
import Head from 'next/head'
import { ErrorComponentProps } from 'components/business/GlobalErrorBoundary'
import useTranslation from 'next-translate/useTranslation'
import { getBackgroundColor, getTextColor } from 'config/themeOverride/getColor'
import { ApplicationDefault } from 'config/constants'

const ForbiddenPage: FC<ErrorComponentProps> = ({
  title,
  description,
  action,
  showAction = true,
}: ErrorComponentProps) => {
  const theme = useMantineTheme()
  const { t } = useTranslation('common')

  return (
    <>
      <Head>
        <title>403 Forbidden</title>
        <style>
          {`body {
            margin: 0;
          }`}
        </style>
      </Head>
      <Center
        className="w-full h-screen"
        style={{
          backgroundColor: getBackgroundColor('gray.0', theme),
        }}
      >
        <Stack spacing={0} align="center" justify="center" style={{ height: '100%' }}>
          <Image src={image.src} width={400} caption={image.caption} mb="xl" alt="403" />
          <Title size={20} mb="xs">
            {title || t('tip.forbidden.forbiddenTitle')}
          </Title>
          <Text color="dimmed" size="sm" mb="xl">
            {description || t('tip.forbidden.forbiddenDescription')}
          </Text>
          <Box>
            {showAction &&
              (action || (
                <Button
                  variant="default"
                  color={getTextColor('gray.9', theme, 'white')}
                  onClick={() => (window.location.href = ApplicationDefault)}
                >
                  {t('actions.returnXM')}
                </Button>
              ))}
          </Box>
        </Stack>
      </Center>
    </>
  )
}

export default ForbiddenPage
