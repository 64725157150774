import { ButtonStylesParams, ContextStylesParams, MantineTheme, TabsStylesParams } from '@mantine/core'
import {
  getBackgroundColor,
  getBorderColor,
  getDefaultBorderColor,
  getDefaultTooltipBorderColor,
  getTextColor,
} from 'config/themeOverride/getColor'
import { merge } from 'lodash-es'

const componentsOverride = {
  AppShell: {
    styles: () => ({
      main: {
        paddingBottom: 0,
      },
    }),
  },
  Select: {
    styles: (theme: MantineTheme) => ({
      dropdown: {
        borderRadius: theme.radius.sm,
      },
      input: {
        paddingLeft: '10px',
        paddingRight: '24px',
      },
      item: {
        borderRadius: theme.radius.xs,
        '&[data-selected]': {
          '&, &:hover': {
            backgroundColor: getBackgroundColor('brandBlue.0', theme, 'dark.4'),
            color: getTextColor('gray.9', theme),
          },
        },
      },
    }),
  },
  Radio: {
    defaultProps: {
      color: 'brandBlue',
    },
    styles: () => ({
      body: {
        display: 'flex',
      },
    }),
  },
  Switch: {
    defaultProps: {
      color: 'brandBlue',
    },
    styles: (theme: MantineTheme) => ({
      trackLabel: {
        fontSize: theme.fontSizes.xs,
      },
      track: {
        cursor: 'pointer',
      },
    }),
  },
  Checkbox: {
    defaultProps: {
      color: 'brandBlue',
    },
    styles: () => ({
      input: {
        borderRadius: 2,
      },
    }),
  },
  Title: {
    defaultProps: {
      order: 3,
    },
  },
  Tabs: {
    defaultProps: {
      activateTabWithKeyboard: false,
      color: 'brandBlue.7',
    },
    styles: (theme: MantineTheme, params: TabsStylesParams, { variant }: ContextStylesParams) => {
      const borderColor = getDefaultBorderColor(theme)
      const activeBorderColor = getBorderColor('brandBlue.7', theme)
      if (variant === 'outline') {
        return {
          tabsList: {
            borderBottomWidth: 0,
          },
          tab: {
            borderWidth: 0,
            paddingTop: 4,
            paddingBottom: 0,
            boxShadow: `inset 0px -1px 0px ${borderColor}, inset -1px 0px 0px ${borderColor}`,
            '&:last-of-type': {
              boxShadow: `inset 0px -1px 0px ${borderColor}`,
            },
            '&[data-active]': {
              boxShadow: `inset -1px 0px 0px ${borderColor}`,
              borderTopColor: activeBorderColor,
              borderTopWidth: 3,
              paddingTop: 0,
            },
            '&[data-active]:last-of-type': {
              boxShadow: 'none',
            },
          },
        }
      }
      return {
        tab: {
          paddingLeft: theme.spacing.sm,
          paddingTop: theme.spacing.sm,
          paddingBottom: theme.spacing.sm,
          marginBottom: '-1px',
          lineHeight: '20px',
          '&[data-active]': {
            color: getTextColor('brandBlue.7', theme, 'dark.0'),
            fontWeight: 600,
          },
        },
        tabsList: {
          borderBottomWidth: '1px',
          borderBottomColor: borderColor,
        },
        tabIcon: {
          marginRight: `${theme.spacing.xs} !important`,
        },
      }
    },
  },
  Anchor: {
    defaultProps: {
      color: 'brandBlue',
    },
  },
  Text: {
    defaultProps: {
      size: 'sm',
      weight: 400,
    },
  },
  Pagination: {
    defaultProps: {
      color: 'brandBlue',
    },
  },
  Navbar: {
    styles: (theme: MantineTheme) => ({
      root: {
        backgroundColor: theme.colorScheme === 'dark' ? undefined : theme.white,
        borderRightColor: getDefaultBorderColor(theme),
      },
    }),
  },
  NavLink: {
    styles: (theme: MantineTheme) => ({
      icon: {
        marginRight: '16px',
        color: getTextColor('gray.9', theme),
      },
      label: {
        lineHeight: '28px',
      },
      root: {
        color: getTextColor('gray.9', theme),
        borderRadius: '4px',
        '&:hover': {
          backgroundColor: getBackgroundColor('gray.0', theme),
        },
        '&[data-active]': {
          color: theme.colors.brandBlue[7],
          backgroundColor: getBackgroundColor('brandBlue.0', theme, 'dark.5'),
        },
        '&[data-active]:hover': {
          backgroundColor: getBackgroundColor('brandBlue.1', theme, 'dark.4'),
        },
        '&[data-active] .mantine-NavLink-icon': {
          color: theme.colors.brandBlue[7],
        },
        '&[data-active] .mantine-NavLink-label': {
          fontWeight: 600,
        },
      },
    }),
  },
  Header: {
    styles: (theme: MantineTheme) => ({
      root: {
        borderBottomColor: getDefaultBorderColor(theme),
      },
    }),
  },
  PasswordInput: {
    styles: () => ({
      innerInput: {
        // Hide Microsoft Edge reveal native icon, because mantine handle this.
        '&::-ms-reveal': {
          display: 'none',
        },
      },
    }),
  },
  Input: {
    styles: (theme: MantineTheme) => ({
      input: {
        borderRadius: theme.radius.sm,
        '&:focus': {
          borderColor: theme.colors.brandBlue[7],
        },
        '&[data-invalid]': {
          color: getTextColor('gray.9', theme),
        },
      },
    }),
  },
  InputWrapper: {
    styles: (theme: MantineTheme) => ({
      label: { marginBottom: theme.spacing.xs },
    }),
  },
  Textarea: {
    styles: () => {
      return {
        input: {
          paddingTop: '6px !important',
          paddingBottom: '6px !important',
          lineHeight: '1.5',
          '&::-webkit-scrollbar': {
            width: '10px',
          },
          '&::-webkit-scrollbar-thumb': {
            borderRadius: '10px',
            border: '2px solid rgba(0, 0, 0, 0)',
            boxShadow: '6px 0 0 rgba(0, 0, 0, 0.35) inset',
          },
        },
      }
    },
  },
  Code: {
    styles: (theme: MantineTheme) => ({
      root: {
        backgroundColor: getBackgroundColor('gray.2', theme, 'dark.5'),
      },
    }),
  },
  Badge: {
    styles: (theme: MantineTheme) => ({
      root: {
        fontSize: theme.fontSizes.xs,
      },
    }),
  },
  Table: {
    defaultProps: {
      withColumnBorders: false,
    },
    styles: (theme: MantineTheme) => ({
      root: {
        borderRadius: theme.radius.sm,
        borderColor: getDefaultBorderColor(theme),
        'thead tr': {
          boxShadow: 'none',
          borderColor: getDefaultBorderColor(theme),
        },
        'thead tr th': {
          height: 40,
          padding: '10px 8px',
          color: getTextColor('gray.7', theme),
          fontWeight: 'normal',
          justifyContent: 'center',
          verticalAlign: 'middle',
          '&:first-of-type': {
            paddingLeft: '24px',
          },
        },
        'thead tr th, tbody tr td': {
          borderBottomColor: getDefaultBorderColor(theme),
        },
        'tbody tr td': {
          padding: '10px 8px',
          height: 40,
          '&:first-of-type': {
            paddingLeft: '24px',
          },
        },
        '&[data-hover] tbody tr:hover td': {
          backgroundColor: getBackgroundColor('gray.0', theme),
        },
      },
    }),
  },
  Menu: {
    defaultProps: {
      width: 160,
    },
    styles: (theme: MantineTheme) => ({
      divider: {
        borderColor: getBorderColor('gray.1', theme),
      },
      item: {
        padding: '7px 12px',
        borderRadius: 4,
        '&:hover': {
          backgroundColor: getBackgroundColor('gray.1', theme),
        },
      },
      itemLabel: {
        lineHeight: 1.5,
      },
      itemIcon: {
        marginRight: 8,
      },
    }),
  },
  Notification: {
    styles: (theme: MantineTheme) => ({
      root: {
        padding: '10px 5px 10px 10px !important',
        maxWidth: 440,
        minWidth: 240,
        backgroundColor: getBackgroundColor('white', theme),
      },
      icon: {
        width: 24,
        height: 24,
      },
    }),
  },
  Stack: {
    defaultProps: {},
  },
  Tooltip: {
    defaultProps: {
      openDelay: 500,
      multiline: true,
    },
    styles: (theme: MantineTheme) => ({
      tooltip: {
        padding: '5px 10px',
        border: `1px solid ${getDefaultTooltipBorderColor(theme)}`,
      },
    }),
  },
  MultiSelect: {
    styles: (theme: MantineTheme) => ({
      value: {
        margin: 3,
      },
      dropdown: {
        borderRadius: theme.radius.xs,
      },
      input: {
        paddingLeft: '12px',
        paddingRight: '30px',
        '&:focus-within': {
          borderColor: theme.colors.brandBlue[7],
        },
      },
    }),
  },
  Rating: {
    defaultProps: {
      fractions: 4,
      readOnly: true,
      color: 'orange.6',
    },
  },
  Button: {
    defaultProps: {
      color: 'brandBlue',
    },
    styles: (theme: MantineTheme, params: ButtonStylesParams, { variant }: ContextStylesParams) => ({
      root: variant === 'subtle' ? { fontWeight: 400 } : {},
      leftIcon: {
        marginRight: theme.spacing.xs,
      },
    }),
  },
  Paper: {
    styles: (theme: MantineTheme) => ({
      root: {
        borderColor: getDefaultBorderColor(theme),
      },
    }),
  },
  Accordion: {
    styles: (theme: MantineTheme) => {
      const borderColor = getDefaultBorderColor(theme)
      return {
        panel: {
          borderTop: `1px solid ${borderColor}`,
        },
        item: {
          borderRadius: theme.radius.md,
          border: `1px solid ${borderColor}`,
          '&[data-active]': { borderColor },
        },
      }
    },
  },
  Progress: {
    defaultProps: {
      color: 'brandBlue.7',
    },
  },
  TextInput: {
    styles: (theme: MantineTheme) => ({
      error: {
        marginTop: theme.spacing.xs,
      },
    }),
  },
  Slider: {
    defaultProps: {
      color: 'brandBlue.7',
    },
  },
  Modal: {
    defaultProps: {
      padding: 'xl',
      centered: true,
      overlayProps: {
        opacity: 0.6,
        color: 'black',
      },
    },
    styles: (theme: MantineTheme, params: any) => {
      const commonStyles = {
        close: {
          color: getTextColor('gray.7', theme),
          '&:focus': {
            outlineWidth: 0,
          },
        },
      }
      if (!params.fullScreen) {
        return commonStyles
      }
      const padding = theme.spacing.xl
      return merge(commonStyles, {
        close: {
          backgroundColor: getBackgroundColor('gray.2', theme, 'dark.5'),
          borderRadius: theme.radius.xl,
          '&:hover': {
            backgroundColor: getBackgroundColor('gray.3', theme, 'dark.4'),
          },
        },
        content: {
          padding: '0 !important',
        },
        header: {
          paddingTop: padding,
          paddingLeft: padding,
          paddingRight: padding,
          marginRight: 0,
        },
        body: {
          height: 'calc(100vh - 68px)',
          width: '100vw',
          overflow: 'auto',
          padding: `0 ${padding}px ${padding}px`,
        },
      })
    },
  },
  RangeCalendar: {
    styles: (theme: MantineTheme) => ({
      day: {
        color: getTextColor('gray.9', theme),
        '&[data-in-range]': {
          backgroundColor: theme.colors.brandBlue[0],
        },
        '&[data-selected]': {
          backgroundColor: theme.colors.brandBlue[7],
        },
      },
    }),
  },
  Popover: {
    styles: (theme: MantineTheme) => ({
      dropdown: {
        borderRadius: theme.radius.sm,
      },
    }),
  },
  ScrollArea: {
    defaultProps: {
      scrollbarSize: 8,
    },
    styles: () => ({
      scrollbar: {
        padding: 0,
      },
      thumb: {
        backgroundColor: 'rgba(0, 0, 0, 0.2)',
        '&:hover': {
          backgroundColor: 'rgba(0, 0, 0, 0.4)',
        },
      },
    }),
  },
  Skeleton: {
    styles: (theme: MantineTheme) => ({
      root: {
        '&::after': {
          backgroundColor: `${getBackgroundColor('gray.1', theme)}!important`,
        },
      },
    }),
  },
  ActionIcon: {
    styles: (theme: MantineTheme) => ({
      root: {
        '&:hover': {
          background: `${getBackgroundColor('gray.1', theme)}`,
        },
      },
    }),
  },
  HoverCard: {
    styles: (theme: MantineTheme) => ({
      dropdown: {
        fontSize: theme.fontSizes.sm,
      },
    }),
  },
}

export default componentsOverride
