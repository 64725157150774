import React, { ReactNode } from 'react'
import { useMounted } from 'hooks'

type Props = {
  children: ReactNode
}
const ClientSideOnly = ({ children }: Props) => {
  const mounted = useMounted()
  if (!mounted) {
    return <></>
  }
  return children as React.ReactElement
}

export default ClientSideOnly
