import { Button } from '@mantine/core'
import { PageEmpty } from 'components/ui'
import { ApplicationDefault } from 'config/constants'
import useTranslation from 'next-translate/useTranslation'
import notFoundImg from 'assets/images/404.png'

const NotFound = () => {
  const { t } = useTranslation('common')
  return (
    <PageEmpty
      width={480}
      height={350}
      image={{ src: notFoundImg.src, width: 160 }}
      description={t('tip.pageNotFound')}
    >
      <a href={ApplicationDefault}>
        <Button>{t('actions.goToHomePage')}</Button>
      </a>
    </PageEmpty>
  )
}

export default NotFound
