import React, { ReactNode } from 'react'
import { Box, Card as MantineCard, Center, Divider, Group, Stack, Text, useMantineTheme } from '@mantine/core'
import cls from 'classnames'
import HelpDoc from 'components/business/HelpDoc'
import { getBackgroundColor } from 'config/themeOverride/getColor'

type Props = {
  title: ReactNode
  helpContent?: string
  helpDocWidth?: number
  description?: ReactNode
  children: ReactNode
  headerRight?: ReactNode
  className?: string
  headerClassName?: string
  childrenClassName?: string
}
const Card = (props: Props) => {
  const theme = useMantineTheme()
  const {
    title,
    description,
    children,
    headerRight,
    className,
    headerClassName,
    childrenClassName = '',
    helpContent,
    helpDocWidth,
  } = props
  return (
    <MantineCard
      withBorder
      shadow="xs"
      p={0}
      className={cls(className)}
      style={{ backgroundColor: getBackgroundColor('white', theme) }}
    >
      <Stack spacing={0} className="h-full">
        <Group position="apart" align="center" className={headerClassName}>
          <Stack spacing={0} py="lg">
            <Group spacing="xs" noWrap px="xl">
              <Text size="md" fw={600}>
                {title}
              </Text>
              {helpContent && <HelpDoc content={helpContent} width={helpDocWidth} />}
            </Group>
            {!!description && <Box px="xl">{description}</Box>}
          </Stack>
          {!!headerRight && <Center pr="xl">{headerRight}</Center>}
        </Group>
        <Divider />
        <Box pt="xl" px="xl" pb="xl" className={childrenClassName}>
          {children}
        </Box>
      </Stack>
    </MantineCard>
  )
}

export default Card
