const workaround = require('next-translate/lib/cjs/plugin/utils.js')
const hoistNonReactStatics = require('hoist-non-react-statics')

workaround.defaultLoader =
  '(locale, namespace) => import(`@next-translate-root/src/locales/${locale}/${namespace}.json`).then(m => m.default)'

const applicationLayout = ['layoutApplication', 'product', 'account', 'componentAppOnboarding', 'permission']
const campaignListLayout = [
  ...applicationLayout,
  'componentCampaignList',
  'componentCampaignDistribution',
  'componentConditionGroup',
  'customer',
]
const campaignBuilderLayout = [
  'componentCampaignBuilder',
  'permission',
  'componentConditionGroup',
  'customer',
  'componentCampaignDistribution',
  'product',
  'account',
  // 不应该有这个
  'componentCampaignList',
  // 投放选择用户群组
  'pageCustomerSegment',
]

const campaignLayout = [
  ...applicationLayout,
  'layoutCampaign',
  'componentCampaignList',
  'componentConditionGroup',
  'customer',
  'componentCampaignDistribution',
  'componentCampaignBuilder',
]

const applicationSettingLayout = [...applicationLayout, 'layoutAppSetting']

module.exports = {
  locales: ['zh-CN'],
  defaultLocale: 'zh-CN',
  pages: {
    'rgx:^((?!banner).)*$': ['common', 'user'], //https://stackoverflow.com/questions/406230/regular-expression-to-match-a-line-that-doesnt-contain-a-word
    'rgx:/account/complete': ['pageAccountComplete'],
    '/signup': ['pageSignup'],
    '/apps/invite/[inviteId]': ['pageAppInvite', 'pageSignup', 'pageLogin'],
    '/login': ['pageLogin'],
    '/password/reset': ['pagePwdReset'],
    'rgx:/apps/new': ['pageAppNew', 'helpdoc', 'account', 'product'],
    'rgx:^/apps/([^/]+)/surveys$': [...campaignListLayout, 'pageSurveys', 'pageTemplate'],
    'rgx:^/apps/([^/]+)/surveys/(([^/]+)/edit)$': [...campaignBuilderLayout, 'componentSurveyBuilder'],
    '/apps/[appId]/surveys/[campaignId]': [...campaignLayout, 'pageSurveyOverview', 'componentTrendChart'],
    '/apps/[appId]/notifications/[campaignId]': [
      ...campaignLayout,
      'pageNotificationOverview',
      'componentTrendChart',
      'pageSurveyOverview',
    ],
    'rgx:^/apps/([^/]+)/feedbacks$': [...campaignListLayout, 'pageFeedback'],
    'rgx:^/apps/([^/]+)/feedbacks/(([^/]+)/edit)$': [...campaignBuilderLayout, 'pageFeedbackEdit'],
    '/apps/[appId]/feedbacks/[campaignId]': [...campaignLayout, 'pageFeedbackOverview', 'componentTrendChart'],
    'rgx:^/apps/([^/]+)/notifications$': [...campaignListLayout, 'pageNotifications'],
    'rgx:^/apps/([^/]+)/notifications/(([^/]+)/edit)$': [
      ...campaignBuilderLayout,
      'componentSurveyBuilder',
      'pageNotificationEdit',
    ],
    'rgx:/entries': [...campaignLayout, 'entry', 'componentEntryFilter', 'pageCustomerSegment'],
    'rgx:/reports': [...campaignLayout, 'entry', 'componentEntryFilter', 'componentFieldsReport'],
    'rgx:/integration': [...campaignLayout, 'componentEntryFilter', 'integration'],
    'rgx:/members': [...applicationLayout, 'pageAppMember'],
    'rgx:/basic': [...applicationSettingLayout, 'pageAppBasic', 'pageAppNew', 'helpdoc'],
    'rgx:/tracking': [...applicationSettingLayout, 'pageAppTracking'],
    'rgx:/webhook': [...applicationSettingLayout, 'integration', 'pageAppWebhook'],
    'rgx:/distribution': [...applicationSettingLayout, 'pageAppDistribution'],
    'rgx:/events': [...applicationSettingLayout, 'pageAppAttributes'],
    'rgx:/customer-attributes': [...applicationSettingLayout, 'pageAppAttributes', 'customer'],
    'rgx:/api': [...applicationSettingLayout, 'pageAppApi'],
    'rgx:/templates': [...applicationLayout, 'pageTemplate'],
    'rgx:/customers': [...applicationLayout, 'customer', 'pageCustomerSegment', 'entry'],
    'rgx:/replies': [...applicationLayout, 'customer', 'pageReply', 'entry', 'pageCustomerSegment'],
    '/system/profile': ['pageProfile', 'permission', 'account'],
    '/system/account': ['pageAccount', 'permission', 'product', 'account'],
    'rgx:/pricing': ['product'],
    'rgx:/subscription': ['pageSubscriptionSuccess'],
  },
  staticsHoc: hoistNonReactStatics,
}
