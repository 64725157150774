import { useCallback, useEffect, useState } from 'react'
import { ApolloClient, NormalizedCacheObject } from '@apollo/client'
import { CachePersistor, LocalStorageWrapper } from 'apollo3-cache-persist'
import cache from 'plugins/apollo/cache'
import { env } from 'config/constants/cookie'

type Props = {
  client: ApolloClient<any>
  key: string
  restoreOnInit?: boolean
}
const usePersistorClient = ({ client, key, restoreOnInit = true }: Props) => {
  const [persistorClient, setPersistorClient] = useState<ApolloClient<any>>()
  const [persistor, setPersistor] = useState<CachePersistor<NormalizedCacheObject>>()

  useEffect(() => {
    async function init() {
      let newPersistor = new CachePersistor({
        key,
        cache,
        storage: new LocalStorageWrapper(window.localStorage),
        debug: env == 'dev',
        trigger: 'write',
      })
      if (restoreOnInit) {
        await newPersistor.restore()
      }
      setPersistor(newPersistor)
      setPersistorClient(client)
    }

    init().catch(console.error)
  }, [])

  const clearCache = useCallback(() => {
    if (!persistor) {
      return
    }
    persistor.purge().then()
  }, [persistor])

  return { persistorClient, clearCache }
}

export default usePersistorClient
