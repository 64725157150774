import { Duration, normalize, parse, toString } from 'duration-fns'
import { isNil, isString } from 'lodash-es'

const DurationType = {
  serialize: (value: Duration): string | null => {
    if (isNil(value)) {
      return null
    }
    return toString(value)
  },
  parseValue: (value: any): Duration | null => {
    if (isNil(value)) {
      return null
    }
    if (isString(value)) {
      return normalize(parse(value))
    }
    throw new Error('invalid value to parse')
  },
}

export const typeMaps = {
  Duration: DurationType,
  PeriodDuration: DurationType,
}
