import React from 'react'
import Trans from 'next-translate/Trans'
import { Anchor, Center } from '@mantine/core'
import { useAccountSwitcher } from 'hooks'
import { PageEmpty } from 'components/ui'
import submarineImg from 'assets/images/submarine.png'
import { useRouter } from 'next/router'

interface Props {
  targetAccountId: string
}

const AccountMismatch = ({ targetAccountId }: Props) => {
  const switchAccount = useAccountSwitcher()
  const router = useRouter()

  const handleSwitch = () => {
    switchAccount(targetAccountId).then(() => {
      router.reload()
    })
  }

  const Description = (
    <Trans
      i18nKey="common:tip.accountMismatch.description"
      components={{
        component: <Anchor mx={4} onClick={handleSwitch} />,
      }}
    />
  )
  return (
    <Center className="w-full h-screen bg-gray-0">
      <PageEmpty width={480} height={350} image={{ src: submarineImg.src, width: 160 }} description={Description} />
    </Center>
  )
}

export default AccountMismatch
