import React, { createContext, PropsWithChildren, ReactElement, ReactNode, useContext } from 'react'
import { noop } from 'lodash-es'
import { ApolloError } from '@apollo/client'
import ForbiddenError from 'pages/403'
import AccountMismatchError from 'pages/403AccountMismatch'
import NotFoundError from 'pages/404'
import InternalServerError from 'pages/500'
import { ON_XM_GRAPHQL_ERROR } from 'config/constants'

export interface ErrorComponentProps {
  title?: ReactNode
  description?: ReactNode
  showAction?: boolean
  action?: ReactNode
}

type Error = {
  code: '403' | '404' | '500' | '403AccountMismatch'
  originalError?: ApolloError
  errorComponentProps?: ErrorComponentProps
}

type Props = PropsWithChildren
type State = {
  hasError: boolean
  error?: Error
  errorProps?: ErrorComponentProps
}
type Context = {
  dismiss: () => void
  showError: (error: Error) => void
  showNotFound: (error: ApolloError) => void
  showErrorPageByError: (error: ApolloError, errorComponentProps?: ErrorComponentProps) => void
  showErrorByCode: (code: Error['code'], errorComponentProps?: ErrorComponentProps) => void
}

const DEFAULT_STATE: State = {
  hasError: false,
  error: undefined,
  errorProps: undefined,
}

const errorComponentsMap = {
  404: NotFoundError,
  500: InternalServerError,
  403: ForbiddenError,
  '403AccountMismatch': AccountMismatchError,
}

const getErrorComponent = (code: Error['code']) => {
  return errorComponentsMap[code] ?? NotFoundError
}

export const GlobalErrorContext = createContext<Context>({
  showError: noop,
  dismiss: noop,
  showNotFound: noop,
  showErrorPageByError: noop,
  showErrorByCode: noop,
})
export const useGlobalError = () => {
  return useContext(GlobalErrorContext)
}

class GlobalErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = { hasError: false }
  }
  componentDidMount(): void {
    this.setupEvents()
  }
  setupEvents() {
    window.addEventListener(ON_XM_GRAPHQL_ERROR, (event: any) => {
      this.showErrorPageByError(event.detail.error)
    })
  }
  dismiss = () => {
    this.setState(DEFAULT_STATE)
  }
  showError = (error: Error) => {
    const { errorComponentProps } = error
    this.setState({
      hasError: true,
      error: error,
      errorProps: errorComponentProps,
    })
  }
  showErrorByCode = (code: Error['code'], errorComponentProps?: ErrorComponentProps) => {
    this.setState({
      hasError: true,
      error: {
        code,
      },
      errorProps: errorComponentProps,
    })
  }
  showNotFound = (error: ApolloError) => {
    if (error.message === 'error.not_found') {
      this.showError({
        code: '404',
        originalError: error,
      })
    }
  }
  showErrorPageByError = (error: ApolloError, errorProps?: ErrorComponentProps) => {
    switch (error.message) {
      case 'error.not_found':
        this.showError({
          code: '404',
          originalError: error,
        })
        break
      case 'error.forbidden':
        this.showError({
          code: '403',
          originalError: error,
          errorComponentProps: errorProps,
        })
        break
      case 'error.account_mismatch':
        this.showError({
          code: '403AccountMismatch',
          originalError: error,
        })
        break
      default:
        throw error
    }
  }

  render() {
    const ErrorComponent = getErrorComponent(this.state.error?.code!)
    return (
      <GlobalErrorContext.Provider
        value={{
          showError: this.showError,
          dismiss: this.dismiss,
          showNotFound: this.showNotFound,
          showErrorPageByError: this.showErrorPageByError,
          showErrorByCode: this.showErrorByCode,
        }}
      >
        {this.state.hasError && ErrorComponent ? <ErrorComponent {...this.state.errorProps} /> : this.props.children}
      </GlobalErrorContext.Provider>
    )
  }
}

export default GlobalErrorBoundary
