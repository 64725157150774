import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { writeIfAbsent } from 'utils/cookieUtils'
import { HX_ACCOUNT_REFERER, HX_UTM_MEDIUM, HX_UTM_SOURCE } from 'config/constants/cookie'

export default function useAccountSourceTrack(referer: string | undefined) {
  const router = useRouter()
  const { utm_source, utm_medium } = router.query

  useEffect(() => {
    const allDomain = process.env.NEXT_PUBLIC_COOKIE_ALL_DOMAIN
    utm_source && writeIfAbsent({ name: HX_UTM_SOURCE, value: utm_source as string, domain: allDomain })
    utm_medium && writeIfAbsent({ name: HX_UTM_MEDIUM, value: utm_medium as string, domain: allDomain })
    referer && writeIfAbsent({ name: HX_ACCOUNT_REFERER, value: referer, domain: allDomain })
  }, [utm_source, utm_medium, referer])
}
