import { RefObject } from 'react'

type Params = {
  ref: RefObject<HTMLInputElement | HTMLTextAreaElement | null>
}

export default function useInputInsert({ ref }: Params) {
  const insert = (content: string) => {
    const start = ref.current?.selectionStart ?? 0
    const end = ref.current?.selectionEnd ?? 0
    if (ref.current) {
      ref.current.value = ref.current?.value?.substring(0, start) + content + ref.current?.value?.substring(end) + ''
    }
    ref.current?.focus()
    setTimeout(() => {
      ref.current?.setSelectionRange(start + content.length, start + content.length)
    }, 1)
  }

  return {
    insert,
  }
}
