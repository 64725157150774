import React, { forwardRef, ForwardRefRenderFunction, ReactNode } from 'react'
import { Checkbox, CheckboxProps, Tooltip } from '@mantine/core'

interface Props extends CheckboxProps {
  tooltipLabel: ReactNode
  tooltipOpened?: boolean
}

const CheckBoxWithTooltip: ForwardRefRenderFunction<HTMLInputElement, Props> = (
  { tooltipLabel, tooltipOpened, ...checkbox }: Props,
  ref
) => {
  return (
    <Tooltip withArrow position="top-start" label={tooltipLabel} opened={tooltipOpened}>
      <Checkbox {...checkbox} ref={ref} />
    </Tooltip>
  )
}

export default forwardRef(CheckBoxWithTooltip)
