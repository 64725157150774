import { User } from 'types/domain'

export const getAvatarUrlByUser = (user?: User | null) => {
  if (user?.avatarUrl) {
    return user.avatarUrl
  }
  return user?.avatarIndex ? getAvatarUrlByAvatarIndex(user.avatarIndex) : undefined
}

export const getAvatarUrlByAvatarIndex = (avatarIndex: string) => {
  return require(`assets/images/avatars/${avatarIndex}.jpg`)?.default?.src
}
