import { openConfirmModal } from '@mantine/modals'
import { Group, Text, Title, useMantineTheme } from '@mantine/core'
import { IconAlertCircle } from '@tabler/icons-react'
import React from 'react'
import useTranslation from 'next-translate/useTranslation'

export default function useDeleteModal(
  title: string,
  content: string,
  onConfirm?: () => void,
  confirmLabel?: string,
  cancelLabel?: string
) {
  const theme = useMantineTheme()
  const { t } = useTranslation('common')

  const openModal = () => {
    return openConfirmModal({
      title: (
        <Group spacing="xs">
          <IconAlertCircle color={theme.colors.red[6]} />
          <Title order={5}>{title}</Title>
        </Group>
      ),
      children: <Text size="sm">{content}</Text>,
      labels: { confirm: confirmLabel ?? t('actions.delete'), cancel: cancelLabel ?? t('actions.cancel') },
      confirmProps: { color: 'red' },
      onConfirm: onConfirm,
      radius: 'sm',
    })
  }

  return {
    openModal,
  }
}
