import React, { forwardRef, ForwardRefRenderFunction, useImperativeHandle, useRef } from 'react'
import { Textarea as MantineTextarea } from '@mantine/core'
import { useInputCompositionProps } from 'hooks'
import { TextareaProps } from '@mantine/core/lib/Textarea/Textarea'
import EmojiPicker from 'components/ui/EmojiPicker'
import useInputInsert from 'components/ui/hooks/useInputInsert'

interface Props extends TextareaProps {
  onChange: (value: any) => void
  showEmoji?: boolean
}

export interface TextareaRef {
  inCompositionRef: React.MutableRefObject<boolean>
  handleOnChange: (value: string) => void
}

const Textarea: ForwardRefRenderFunction<TextareaRef, Props> = (
  { value, onChange, showEmoji = false, maxLength = 500, ...rest },
  ref
) => {
  const {
    handleOnChange: propHandleOnChange,
    handleComposition,
    inCompositionRef,
  } = useInputCompositionProps(maxLength, onChange)

  useImperativeHandle(ref, () => ({ inCompositionRef, handleOnChange }))
  const textAreaRef = useRef<HTMLTextAreaElement>(null)
  const { insert } = useInputInsert({ ref: textAreaRef })

  const handleOnChange = (value: string) => {
    textAreaRef.current!.value = value
    propHandleOnChange(value)
  }

  const handleEmojiSelect = (emoji: string) => {
    insert(emoji)
    propHandleOnChange(textAreaRef.current?.value ?? '')
  }

  return (
    <MantineTextarea
      {...rest}
      ref={textAreaRef}
      value={value}
      onChange={(event) => handleOnChange(event.currentTarget.value)}
      onCompositionStart={handleComposition}
      onCompositionEnd={handleComposition}
      rightSection={showEmoji && <EmojiPicker onEmojiSelect={handleEmojiSelect} />}
      sx={(theme) => ({
        '.mantine-Textarea-rightSection': {
          top: 'unset',
          bottom: theme.spacing.xs,
        },
      })}
    />
  )
}

export default forwardRef(Textarea)
