import React, { HTMLAttributeAnchorTarget, PropsWithChildren, ReactNode } from 'react'
import { Anchor } from '@mantine/core'
import Link, { LinkProps } from 'next/link'

interface Props extends LinkProps {
  text?: ReactNode
  target?: HTMLAttributeAnchorTarget
  className?: string
  underline?: boolean
}

const AnchorLink = (props: PropsWithChildren<Props>) => {
  const { href, children, text, target, underline, onClick, ...restProps } = props

  return (
    <Link href={href || ''} passHref target={target} {...restProps} legacyBehavior>
      <Anchor target={target} underline={underline} className={restProps.className} onClick={onClick}>
        {children ?? text}
      </Anchor>
    </Link>
  )
}

export default AnchorLink
