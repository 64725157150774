import { useEffect } from 'react'
import { useRouter } from 'next/router'
import { XM_COLOR_SCHEME } from 'config/constants/localStorage'
import { ColorScheme } from '@mantine/core'
import { useLocalStorage } from '@mantine/hooks'
import { isNil } from 'lodash-es'

export default function useColorScheme() {
  const { dark } = useRouter().query
  const [colorScheme, setColorScheme] = useLocalStorage<ColorScheme>({
    key: XM_COLOR_SCHEME,
    defaultValue: dark === '1' ? 'dark' : 'light',
    getInitialValueInEffect: true,
  })

  const toggleColorScheme = (value?: ColorScheme) =>
    setColorScheme(value || (colorScheme === 'dark' ? 'light' : 'dark'))

  useEffect(() => {
    isNil(dark) || setColorScheme(dark === '1' ? 'dark' : 'light')
  }, [dark, setColorScheme])

  return {
    colorScheme,
    toggleColorScheme,
  }
}
