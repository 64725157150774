import { handleGlobalErrors } from 'plugins/apollo/links'
import { useEffect } from 'react'
import { useMounted } from 'hooks'

const useServerSideErrors = (error: any) => {
  const mounted = useMounted()

  useEffect(() => {
    if (error) {
      const { graphQLErrors, networkError } = JSON.parse(error)
      handleGlobalErrors(graphQLErrors, networkError)
    }
  }, [error, mounted])
}

export default useServerSideErrors
