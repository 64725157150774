export const ApplicationDefault = '/apps'
const ApplicationPrefix = `${ApplicationDefault}/[appId]`

export const AccountComplete = '/account/complete'
export const ApplicationFeedback = `${ApplicationPrefix}/feedbacks`
export const ApplicationReply = `${ApplicationPrefix}/replies`
export const ApplicationFeedbackShow = `${ApplicationFeedback}/[campaignId]`
export const ApplicationFeedbackEdit = `${ApplicationPrefix}/feedbacks/[campaignId]/edit`
export const ApplicationFeedbackEntries = `${ApplicationFeedback}/[campaignId]/entries`
export const ApplicationFeedbackEntry = `${ApplicationFeedback}/[campaignId]/entries/[serialNumber]`
export const ApplicationFeedbackIntegration = `${ApplicationFeedback}/[campaignId]/integration`
export const ApplicationSurvey = `${ApplicationPrefix}/surveys`
export const ApplicationSurveyShow = `${ApplicationSurvey}/[campaignId]`
export const ApplicationSurveyEdit = `${ApplicationSurvey}/[campaignId]/edit`
export const ApplicationSurveyReports = `${ApplicationSurvey}/[campaignId]/reports`
export const ApplicationSurveyEntries = `${ApplicationSurvey}/[campaignId]/entries`
export const ApplicationSurveyEntry = `${ApplicationSurvey}/[campaignId]/entries/[serialNumber]`
export const ApplicationSurveyIntegration = `${ApplicationSurvey}/[campaignId]/integration`
export const ApplicationSettings = `${ApplicationPrefix}/setting/[settingName]`
export const ApplicationSettingsCodeBasic = `${ApplicationPrefix}/setting/basic`
export const ApplicationSettingsCodeTracking = `${ApplicationPrefix}/setting/tracking`
export const ApplicationSettingsDistribution = `${ApplicationPrefix}/setting/distribution`
export const ApplicationSettingsEvents = `${ApplicationPrefix}/setting/events`
export const ApplicationSettingsCustomerAttributes = `${ApplicationPrefix}/setting/customer-attributes`
export const ApplicationSettingsWebhook = `${ApplicationPrefix}/setting/webhook`
export const ApplicationSettingsApi = `${ApplicationPrefix}/setting/api`
export const ApplicationMembers = `${ApplicationPrefix}/members`
export const ApplicationIntegration = `${ApplicationPrefix}/integration`
export const ApplicationNew = '/apps/new'
export const ApplicationMemberInvite = `${ApplicationDefault}/invite/[inviteId]`
export const ApplicationTemplates = `${ApplicationPrefix}/templates`
export const ApplicationTemplate = `${ApplicationPrefix}/templates/[code]`

export const ApplicationCustomers = `${ApplicationPrefix}/customers`
export const ApplicationSegmentCustomer = `${ApplicationPrefix}/customers/[segmentId]`

export const ApplicationNotification = `${ApplicationPrefix}/notifications`
export const ApplicationNotificationShow = `${ApplicationNotification}/[campaignId]`
export const ApplicationNotificationEdit = `${ApplicationNotification}/[campaignId]/edit`
export const ApplicationNotificationReports = `${ApplicationNotification}/[campaignId]/reports`
export const ApplicationNotificationEntries = `${ApplicationNotification}/[campaignId]/entries`
export const ApplicationNotificationEntry = `${ApplicationNotification}/[campaignId]/entries/[serialNumber]`
export const ApplicationNotificationIntegration = `${ApplicationNotification}/[campaignId]/integration`

export const SystemPrefix = '/system'
export const SystemProfile = `${SystemPrefix}/profile`
export const SystemAccount = `${SystemPrefix}/account`

export const Index = '/'
export const Login = '/login'
export const Signup = '/signup'
export const Terms = '/terms'
export const Privacy = '/privacy'
export const Templates = '/templates'
export const Template = '/templates/[templateId]'
export const Pricing = '/pricing'
export const Institute = 'https://howxm.com/institute'
export const HelpCenter = 'https://howxm.com/help/'
export const whyNotSurveyCase = 'https://howxm.com/institute/howxm-vs-traditional-survey/'
export const whyNotCemCase = 'https://howxm.com/institute/howxm-vs-cem/'
export const whyNotOverseasCase = 'https://howxm.com/institute/howxm-vs-overseas-products/'
export const whyNotDevCase = 'https://howxm.com/institute/howxm-vs-dev/'

export const PasswordReset = '/password/reset'

export const NotFound = '/404'
export const Forbidden = '/403'

export const PublicPaths = [
  Index,
  Signup,
  Login,
  ApplicationMemberInvite,
  Terms,
  Privacy,
  PasswordReset,
  Templates,
  Template,
  Pricing,
]
