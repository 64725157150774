import React from 'react'
import { NotificationProps, showNotification } from '@mantine/notifications'
import { IconCheck, IconX } from '@tabler/icons-react'

const message = {
  success(options: NotificationProps) {
    showNotification({
      autoClose: 2000,
      color: 'green',
      icon: <IconCheck size={20} />,
      ...options,
    })
  },
  successMsg(message: React.ReactNode, title?: React.ReactNode) {
    this.success({ message, title })
  },
  error(message: React.ReactNode) {
    showNotification({
      message,
      color: 'red',
      autoClose: 3000,
      icon: <IconX size={20} />,
    })
  },
}

export default message
