import { isEmpty } from 'lodash-es'
import { Anchor, Text, Tooltip, useMantineTheme } from '@mantine/core'
import { getTextColor } from 'config/themeOverride/getColor'
import URL from 'url-parse'
import { isUrl } from 'utils'

type Props = {
  url: string
  includeProtocolAndHost?: boolean
}
const UrlWithTooltip = (props: Props) => {
  const { url, includeProtocolAndHost = false } = props
  const theme = useMantineTheme()
  if (isEmpty(url)) {
    return null
  }

  const isValidUrl = isUrl(url)

  const getLabel = () => {
    if (includeProtocolAndHost || !isValidUrl) {
      return url
    }
    const parsed = new URL(url)
    parsed.set('protocol', '')
    parsed.set('host', '')
    return parsed.href
  }

  return (
    <Tooltip.Floating
      label={url}
      px="sm"
      multiline
      styles={{
        tooltip: {
          maxWidth: 520,
          wordBreak: 'break-all',
        },
      }}
    >
      {isValidUrl ? (
        <Anchor
          lineClamp={1}
          className="block whitespace-nowrap"
          color={getTextColor('brandBlue.8', theme)}
          href={url}
          target="_blank"
        >
          {getLabel()}
        </Anchor>
      ) : (
        <Text className="block" lineClamp={1}>
          {getLabel()}
        </Text>
      )}
    </Tooltip.Floating>
  )
}

export default UrlWithTooltip
