import React, { CSSProperties, FC } from 'react'
import FeedbackIcon from './icons/feedback.svg'
import EmptyIcon from './icons/empty-picture.svg'
import Check2 from './icons/2check.svg'
import ArrowAutofitDown from './icons/arrow-autofit-down.svg'
import WidgetStar from './icons/widget-star.svg'
import WidgetComment from './icons/widget-comment.svg'
import WidgetSmile from './icons/widget-smile.svg'
import SurveyIcon from './icons/survey.svg'
import Plus from './icons/plus.svg'
import PlusAlt from './icons/plus-alt.svg'
import Nps from './icons/nps.svg'
import Ending from './icons/ending.svg'
import welcome from './icons/welcome.svg'
import PaperIcon from './icons/paper.svg'
import PaperFillIcon from './icons/paper-fill.svg'
import SendIcon from './icons/send.svg'
import SendFillIcon from './icons/send-fill.svg'
import paletteIcon from './icons/palette.svg'
import paletteFillIcon from './icons/palette-fill.svg'
import Checkbox from './icons/checkbox.svg'
import Csat from './icons/csat.svg'
import Ces from './icons/ces.svg'
import Radio from './icons/radio.svg'
import Delete from './icons/delete.svg'
import TextArea from './icons/text-area.svg'
import TextField from './icons/text-field.svg'
import Rating from './icons/rating.svg'
import Screenshot from './icons/screenshot.svg'

import StarIcon from './icons/star.svg'
import Star2Icon from './icons/star2.svg'
import UserIcon from './icons/user.svg'
import UpCloudIcon from './icons/up-cloud.svg'
import TimeIcon from './icons/time.svg'
import SignPaperIcon from './icons/sign-paper.svg'
import MapIcon from './icons/map.svg'
import EarthIcon from './icons/earth.svg'
import CalendarIcon from './icons/calendar.svg'
import BrowserIcon from './icons/browser.svg'
import ResolutionIcon from './icons/resolution.svg'
import OsIcon from './icons/os.svg'
import commentIcon from './icons/comment.svg'
import TerminalIcon from './icons/terminal.svg'
import DateTimeIcon from './icons/dateTime.svg'
import EmailIcon from './icons/email.svg'
import InfoIcon from './icons/info.svg'
import MobileIcon from './icons/mobile.svg'
import UidIcon from './icons/uid.svg'
import HelpIcon from './icons/help.svg'

import UsersIcon from './icons/users.svg'
import TranslationIcon from './icons/translation.svg'
import AlarmIcon from './icons/alarm.svg'
import ScreencastIcon from './icons/screencast.svg'
import BounceIcon from './icons/bounce.svg'
import SparklesIcon from './icons/sparkles.svg'

import RadioButtonIcon from './icons/radio-button.svg'
import HourglassIcon from './icons/hourglass.svg'
import DoneFillIcon from './icons/doneFill.svg'
import ChatFillIcon from './icons/chatFill.svg'
import PageIcon from './icons/page.svg'
import DistributionIcon from './icons/distribution.svg'
import InfoCircle from './icons/info-circle.svg'

import FilterIcon from './icons/filter.svg'

import comments from './icons/comments.svg'
import commented from './icons/commented.svg'
import vip from './icons/vip.svg'
import reply from './icons/reply.svg'

import tabChart from './icons/tab-chart.svg'
import tabFeedback from './icons/tab-feedback.svg'
import tabHelp from './icons/tab-help.svg'
import tabInformation from './icons/tab-information.svg'
import tabSetting from './icons/tab-setting.svg'
import tabSummary from './icons/tab-summary.svg'
import tabSurvey from './icons/tab-survey.svg'
import tabTable from './icons/tab-table.svg'
import tabTeammates from './icons/tab-teammates.svg'
import tabIntegration from './icons/tab-integration.svg'
import tabReply from './icons/tabReply.svg'
import tabReplyFill from './icons/tabReplyFill.svg'
import tabNotification from './icons/tabNotification.svg'
import tabNotificationFill from './icons/tabNotificationFill.svg'

import tabChartFill from './icons/tab-chart-fill.svg'
import tabFeedbackFill from './icons/tab-feedback-fill.svg'
import tabHelpFill from './icons/tab-help-fill.svg'
import tabInformationFill from './icons/tab-information-fill.svg'
import tabSettingFill from './icons/tab-setting-fill.svg'
import tabSummaryFill from './icons/tab-summary-fill.svg'
import tabSurveyFill from './icons/tab-survey-fill.svg'
import tabTableFill from './icons/tab-table-fill.svg'
import tabTeammatesFill from './icons/tab-teammates-fill.svg'
import tabTemplates from './icons/tab-templates.svg'
import tabTemplatesFill from './icons/tab-templates-fill.svg'
import tabIntegrationFill from './icons/tab-integration-fill.svg'

import building from './icons/building.svg'
import buildingFill from './icons/building-fill.svg'

import platformIos from './icons/platform-ios.svg'
import platformWeb from './icons/platform-web.svg'
import platformLink from './icons/platform-link.svg'
import platformWechat from './icons/platform-wechat.svg'
import platformAndroid from './icons/platform-android.svg'

import logoMini from './icons/logo-mini.svg'
import logoFull from './icons/logo-full.svg'
import cursorBox from './icons/cursor-box.svg'

import webIcon from './icons/web.svg'
import miniappIcon from './icons/miniapp.svg'
import mobile4Site from './icons/mobile4Site.svg'
import description from './icons/description.svg'
import cta from './icons/cta.svg'
import newFeature from './icons/newFeature.svg'
import affiliate from './icons/affiliate.svg'

import helpCenter from './icons/helpCenter.svg'
import blog from './icons/blog.svg'
import newFunction from './icons/newFunction.svg'
import one2oneServe from './icons/one2oneServe.svg'
import onlineCustomers from './icons/onlineCustomers.svg'
import followUs from './icons/followUs.svg'
import brandWeCom from './icons/brand-weCom.svg'
import brandDingtalk from './icons/brand-dingtalk.svg'
import brandFeishu from './icons/brand-feishu.svg'
import tabCustomer from './icons/tabCustomer.svg'
import tabCustomerFill from './icons/tabCustomerFill.svg'
import wechat from './icons/wechat.svg'

export const iconMap = {
  radioButton: RadioButtonIcon,
  hourglass: HourglassIcon,
  sparkles: SparklesIcon,
  feedback: FeedbackIcon,
  empty: EmptyIcon,
  check2: Check2,
  arrowAutofitDown: ArrowAutofitDown,
  widgetStar: WidgetStar,
  widgetComment: WidgetComment,
  widgetSmile: WidgetSmile,
  plus: Plus,
  plusAlt: PlusAlt,
  nps: Nps,
  ending: Ending,
  welcome,
  survey: SurveyIcon,
  paper: PaperIcon,
  paperFill: PaperFillIcon,
  send: SendIcon,
  sendFill: SendFillIcon,
  palette: paletteIcon,
  paletteFill: paletteFillIcon,
  checkbox: Checkbox,
  star: StarIcon,
  star2: Star2Icon,
  csat: Csat,
  ces: Ces,
  radio: Radio,
  delete: Delete,
  textArea: TextArea,
  textField: TextField,
  rating: Rating,
  user: UserIcon,
  upCloud: UpCloudIcon,
  time: TimeIcon,
  signPaper: SignPaperIcon,
  map: MapIcon,
  earth: EarthIcon,
  calendar: CalendarIcon,
  browser: BrowserIcon,
  resolution: ResolutionIcon,
  os: OsIcon,
  comment: commentIcon,
  uv: UsersIcon,
  pv: TranslationIcon,
  sessionDuration: AlarmIcon,
  pvPerSession: ScreencastIcon,
  bounce: BounceIcon,
  terminal: TerminalIcon,
  doneFill: DoneFillIcon,
  chatFill: ChatFillIcon,
  page: PageIcon,
  distribution: DistributionIcon,
  dateTime: DateTimeIcon,
  email: EmailIcon,
  info: InfoIcon,
  mobile: MobileIcon,
  uid: UidIcon,
  help: HelpIcon,
  filter: FilterIcon,
  infoCircle: InfoCircle,
  screenshot: Screenshot,
  comments,
  commented,
  vip,
  tabChart,
  tabFeedback,
  tabHelp,
  tabInformation,
  tabSetting,
  tabSummary,
  tabSurvey,
  tabTable,
  tabTeammates,
  tabIntegration,
  tabChartFill,
  tabFeedbackFill,
  tabHelpFill,
  tabInformationFill,
  tabSettingFill,
  tabSummaryFill,
  tabSurveyFill,
  tabTableFill,
  tabTeammatesFill,
  tabIntegrationFill,
  building,
  buildingFill,
  platformIos,
  platformWeb,
  platformLink,
  platformWechat,
  platformAndroid,
  logoMini,
  logoFull,
  cursorBox,
  web: webIcon,
  miniapp: miniappIcon,
  mobile4Site: mobile4Site,
  description,
  cta,
  newFeature,
  affiliate,
  helpCenter,
  blog,
  newFunction,
  one2oneServe,
  onlineCustomers,
  followUs,
  tabTemplates,
  tabTemplatesFill,
  brandWeCom,
  brandDingtalk,
  brandFeishu,
  tabCustomer,
  tabCustomerFill,
  reply,
  wechat,
  tabReply,
  tabReplyFill,
  tabNotification,
  tabNotificationFill,
}

type Props = {
  name: keyof typeof iconMap
  className?: string
  style?: CSSProperties
  size?: number
}

const Icon: FC<Props> = (props) => {
  const { name, className = '', style, size } = props
  const IconComponent = iconMap[name]

  return (
    <span style={style} className={'flex ' + className} data-testid={name}>
      {size && <IconComponent width={size} height={size} />}
      {!size && <IconComponent />}
    </span>
  )
}

export default Icon
