import { PageEmpty } from 'components/ui'
import useTranslation from 'next-translate/useTranslation'
import submarineImg from 'assets/images/submarine.png'

const ServerError = () => {
  const { t } = useTranslation('common')
  return (
    <PageEmpty
      width={480}
      height={350}
      image={{ src: submarineImg.src, width: 160 }}
      description={t('tip.serverError')}
    />
  )
}

export default ServerError
