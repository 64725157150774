import { ApolloLink, HttpLink, Operation, split } from '@apollo/client'
import { BatchHttpLink } from '@apollo/client/link/batch-http'
import { onError } from '@apollo/client/link/error'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { getMainDefinition } from '@apollo/client/utilities'
import { withScalars } from 'apollo-link-scalars'
import { apiServer } from 'config'

import { Login, ON_XM_GRAPHQL_ERROR, PublicPaths } from 'config/constants'
import { HX_LOGIN_PRE_ROUTE } from 'config/constants/cookie'
import fetch from 'cross-fetch'
import { buildClientSchema, GraphQLError } from 'graphql'
import { createClient } from 'graphql-ws'
import router from 'next/router'
import omitDeep from 'omit-deep-lodash'
import appContext from 'service'
import schema from 'types/schema.json'
import { write } from 'utils/cookieUtils'
import isServer from 'utils/isServer'
import { typeMaps } from './scalar'

const singleHttpLinkUri = apiServer.graphqlUrl
const batchHttpLinkUri = apiServer.graphqlUrl
const publicHttpLinkUri = apiServer.publicGraphqlUrl
const wsLinkUri = apiServer.graphqlSubscriptionUrl as string

export const errorUnauthorized = 'error.unauthorized'
export const getAuthorization = () => `Bearer ${appContext.getSystemToken()}` // TODO

export const authMiddleware = new ApolloLink((operation, forward: any) => {
  const sharedCookie = {
    Authorization: getAuthorization(),
  }
  operation.setContext({
    headers: sharedCookie,
  })
  operation.variables = omitDeep(operation.variables, '__typename')
  return forward(operation)
})

export function handleGlobalErrors(
  graphQLErrors: ReadonlyArray<GraphQLError> | undefined,
  networkError:
    | Error
    | (Error & { response: Response; statusCode: number; bodyText: string })
    | (Error & { response: Response; result: Record<string, any>; statusCode: number })
    | null
    | undefined,
  operation?: Operation
) {
  if (graphQLErrors) {
    graphQLErrors.map((error) => {
      const { message, locations, path, extensions } = error
      if (extensions?.code === errorUnauthorized && !PublicPaths.includes(router.pathname)) {
        appContext.reset()
        write({
          name: HX_LOGIN_PRE_ROUTE,
          value: window.location.href,
          domain: process.env.NEXT_PUBLIC_COOKIE_ALL_DOMAIN,
        })
        window.location.href = Login
      }

      const errorEvent = new CustomEvent(ON_XM_GRAPHQL_ERROR, {
        detail: {
          error,
        },
      })

      window.dispatchEvent(errorEvent)

      console.log(
        `[GraphQL error]: Message: ${message}
        Operation: ${operation?.operationName}, Location: ${JSON.stringify(locations)}, Path: ${path}`
      )
    })
  }
  if (networkError) {
    // tslint:disable-next-line:no-console
    console.log(`[Network error]: ${JSON.stringify(networkError)}`)
  }
}

export const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  handleGlobalErrors(graphQLErrors, networkError, operation)
})

export const linkHeaders = {
  'content-type': 'application/json;charset=UTF-8',
}

const singleHttpLink = new HttpLink({
  uri: (params: Operation) => {
    return `${singleHttpLinkUri}?op=${params.operationName}`
  },
  headers: linkHeaders,
  fetch,
})

const batchHttpLink = new BatchHttpLink({
  uri: batchHttpLinkUri,
  headers: linkHeaders,
  batchMax: 5,
  fetch,
})

const publicHttpLink = new HttpLink({
  uri: (params: Operation) => {
    return `${publicHttpLinkUri}?op=${params.operationName}`
  },
  headers: linkHeaders,
  fetch,
})

const excludeBatchOperationNames = [
  'applicationBasicInfo',
  'campaignTemplates',
  'Customers',
  'campaignEntries',
  'upsertCampaignSetting',
  'createEntryReply',
  'deleteEntryReply',
]

const excludeBatchOperationNamePattern = /^application\w+DailyStats$/

function shouldBatch({ operationName }: Operation) {
  return !excludeBatchOperationNames.includes(operationName) && !excludeBatchOperationNamePattern.test(operationName)
}

const privateHttpLink = split((op) => shouldBatch(op), batchHttpLink, singleHttpLink)

const httpLink = split(
  ({ getContext }) => {
    return getContext().public
  },
  publicHttpLink,
  privateHttpLink
)

const wsLink = isServer
  ? null
  : new GraphQLWsLink(
      createClient({
        url: wsLinkUri,
        lazy: true,
        connectionParams: () => ({
          authToken: getAuthorization(),
        }),
      })
    )

const graphqlLink =
  isServer || wsLink == null
    ? httpLink
    : split(
        ({ query }) => {
          const def = getMainDefinition(query)
          return def.kind === 'OperationDefinition' && def.operation === 'subscription'
        },
        wsLink,
        httpLink
      )

export const scalarsLink = withScalars({
  // @ts-ignore
  schema: buildClientSchema(schema),
  // todo fix tslint error
  // @ts-ignore
  typesMap: typeMaps,
})

export default ApolloLink.from([scalarsLink, authMiddleware, errorLink, graphqlLink])
