
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __appWithI18n from 'next-translate/appWithI18n'
// @ts-ignore
    
import React from 'react'
import Head from 'next/head'
import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'
import { AppPropsWithLayout } from 'types/nextCustomType'
import 'styles/globals.scss'
import dynamic from 'next/dynamic'
import XmLayout from 'components/business/Layouts/XmAppLayout'
import useApollo from 'hooks/useApollo'
import { ApolloProvider } from '@apollo/client'
import GlobalErrorBoundary from 'components/business/GlobalErrorBoundary'
import usePersistorClient from 'components/business/hooks/usePersistorClient'
import { XM_DATA } from 'config/constants/localStorage'
import { ColorSchemeProvider, MantineProvider } from '@mantine/core'
import themeOverride from 'config/themeOverride'
import { PublicPaths } from 'config/constants'
import useColorScheme from 'hooks/useColorScheme'

const BannerApp = dynamic(() => import('components/business/BannerLayout/BannerApp'))

const App = ({ Component, pageProps }: AppPropsWithLayout) => {
  const { title, pageTitle, pageKeywords, pageDescription, error } = pageProps
  const { t } = useTranslation('common')
  const getLayout = Component.getLayout ?? ((page) => page)
  const client = useApollo(pageProps)
  const { persistorClient } = usePersistorClient({ client: client, key: XM_DATA })

  const { colorScheme, toggleColorScheme } = useColorScheme()
  const router = useRouter()
  const isBannerPath = router.pathname.startsWith('/banner')

  if (isBannerPath) {
    return <BannerApp title={title}>{getLayout(<Component {...pageProps} />, pageProps)}</BannerApp>
  } else {
    const finalPageTitle = pageTitle ? `${pageTitle}-${t('howxm.title')}` : t('howxm.title')
    return (
      <>
        <Head>
          <title>{finalPageTitle}</title>
          <meta name="referrer" content="unsafe-url" />
          <link rel="icon" href="/favicon.ico" />
          <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
          <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
          <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
          <link rel="manifest" href="/site.webmanifest" />
          <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
          <link rel="shortcut icon" type="image/png" href="/logo.png" data-original-href="/logo.png" />
          <meta name="msapplication-TileColor" content="#da532c" />
          <meta name="theme-color" content="#ffffff" />
        </Head>
        <ApolloProvider client={persistorClient ?? client}>
          <ColorSchemeProvider colorScheme={colorScheme} toggleColorScheme={toggleColorScheme}>
            <MantineProvider
              withCSSVariables
              withGlobalStyles
              withNormalizeCSS
              theme={{ ...themeOverride, colorScheme: PublicPaths.includes(router.pathname) ? 'light' : colorScheme }}
            >
              <GlobalErrorBoundary>
                <XmLayout
                  pageTitle={finalPageTitle}
                  pageKeywords={pageKeywords}
                  pageDescription={pageDescription}
                  error={error}
                >
                  {getLayout(<Component {...pageProps} />, pageProps)}
                </XmLayout>
              </GlobalErrorBoundary>
            </MantineProvider>
          </ColorSchemeProvider>
        </ApolloProvider>
      </>
    )
  }
}

const __Page_Next_Translate__ = App


// @ts-ignore
    export default __appWithI18n(__Page_Next_Translate__, {
// @ts-ignore
      ...__i18nConfig,
// @ts-ignore
      isLoader: true,
// @ts-ignore
      skipInitialProps: false,
// @ts-ignore
      loadLocaleFrom: (locale, namespace) => import(`@next-translate-root/src/locales/${locale}/${namespace}.json`).then(m => m.default),
// @ts-ignore
    });
// @ts-ignore
  