import { AccountComplete, ApplicationNew } from 'config/constants'
import { showBackBtnQueryKey } from 'components/business/Layouts/OnboardingLayout/constant'
import useCurrentUserCache from 'components/business/hooks/useCurrentUserCache'
import { getCreatedAccount } from 'utils/accountUtils'

export default function useCreateApplicationPath() {
  const { user } = useCurrentUserCache()
  const account = getCreatedAccount(user)

  return async () => {
    if (!account) {
      return AccountComplete
    }
    return ApplicationNew + '?' + showBackBtnQueryKey + '=1'
  }
}
