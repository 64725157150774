import { ApolloClient, ApolloLink, createHttpLink, InMemoryCache, type NormalizedCacheObject } from '@apollo/client'
import merge from 'deepmerge'

import { apiServer } from 'config'
import cache from './cache'
import link, { scalarsLink } from './links'

export const APOLLO_STATE_PROP_NAME = '__APOLLO_STATE__'

let apolloClient: ApolloClient<NormalizedCacheObject> | null

function buildServerSideClient() {
  return new ApolloClient({
    ssrMode: true,
    cache: new InMemoryCache(),
    link: ApolloLink.from([
      scalarsLink,
      createHttpLink({
        uri: apiServer.graphqlUrl,
      }),
    ]),
    defaultOptions: {
      query: {
        fetchPolicy: 'network-only',
      },
    },
  })
}

function buildClientSideClient() {
  return new ApolloClient({
    ssrMode: false,
    cache,
    link,
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'cache-first', // useQuery
      },
      query: {
        fetchPolicy: 'network-only', // client.query
      },
    },
  })
}

function createApolloClient() {
  return typeof window === 'undefined' ? buildServerSideClient() : buildClientSideClient()
}

export function initializeApollo(initialState?: any) {
  const _apolloClient = apolloClient ?? createApolloClient()

  if (initialState) {
    const existingCache = _apolloClient.cache.extract()
    const data = merge(existingCache, initialState, {
      arrayMerge: (destinationArray, sourceArray) => sourceArray,
    })
    _apolloClient.restore(data)
  }

  if (typeof window === 'undefined') {
    return _apolloClient
  }

  if (!apolloClient) {
    apolloClient = _apolloClient
  }

  return _apolloClient
}

export function addApolloState(client: ApolloClient<NormalizedCacheObject>, pageProps: any) {
  if (pageProps?.props) {
    pageProps.props[APOLLO_STATE_PROP_NAME] = client.cache.extract()
  }

  return pageProps
}
