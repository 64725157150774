import { createStyles } from '@mantine/core'
import { getBackgroundColor, getBorderColor, getTextColor } from 'config/themeOverride/getColor'

export const useStyles = createStyles((theme) => ({
  root: {
    overflow: 'hidden',
  },
  option: {
    ...theme.fn.focusStyles(),
    fontSize: theme.fontSizes.sm,
    lineHeight: '20px',
    cursor: 'pointer',
    textAlign: 'center',
    border: `1px solid ${getBorderColor('gray.3', theme)}`,
    padding: `calc(${theme.spacing.sm} - 1px)`,

    '&:hover': {
      backgroundColor: getBackgroundColor('gray.1', theme),
    },

    '&:first-of-type': {
      borderTopLeftRadius: theme.radius.sm,
      borderBottomLeftRadius: theme.radius.sm,
    },

    '&:last-of-type': {
      borderTopRightRadius: theme.radius.sm,
      borderBottomRightRadius: theme.radius.sm,
    },

    '&[data-active]': {
      backgroundColor: getBackgroundColor('brandBlue.0', theme, 'brandBlue.2'),
      borderColor: getBorderColor('brandBlue.7', theme, 'brandBlue.7'),
      color: getTextColor('brandBlue.7', theme, 'dark.4'),
    },
  },
}))
