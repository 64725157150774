import { getBackgroundColor, getTextColor } from 'config/themeOverride/getColor'
import { ReactNode } from 'react'
import { Badge } from '@mantine/core'

type Props = {
  value: boolean
  onText: ReactNode
  offText: ReactNode
}

const StatusBadge = ({ value, onText, offText }: Props) => {
  return (
    <Badge
      size="lg"
      styles={(theme) => ({
        root: {
          backgroundColor: value ? getBackgroundColor('teal.1', theme, 'teal.3') : getBackgroundColor('gray.1', theme),
          padding: '3px 8px',
        },
        inner: {
          color: value ? getTextColor('teal.6', theme, 'dark.5') : getTextColor('gray.6', theme),
          fontWeight: 'normal',
        },
        leftSection: {
          marginRight: 4,
        },
      })}
    >
      {value ? onText : offText}
    </Badge>
  )
}
export default StatusBadge
