import React, { useMemo } from 'react'
import { isNil } from 'lodash-es'
import { NumberInput, Select } from '@mantine/core'
import { Duration, parse, toUnit } from 'duration-fns'
import { UNITS_META_MAP } from 'duration-fns/dist/lib/units'
import useTranslation from 'next-translate/useTranslation'
import { calcDurationUnit } from 'utils'

type UnitType = keyof typeof UNITS_META_MAP

interface Props {
  unit: UnitType
  value?: Duration | null
  onChange: (value: Duration | null, unit: UnitType) => void
  supportUnits?: Partial<UnitType>[]
}

const defaultUnits: Partial<UnitType>[] = ['minutes', 'hours', 'days', 'weeks']

const DurationInput = ({ value, onChange, supportUnits = defaultUnits, unit: propsUnit }: Props) => {
  const { t } = useTranslation('common')
  const unit = propsUnit ?? calcDurationUnit(supportUnits, value)
  const count = isNil(value) ? undefined : toUnit(value!, unit)

  const onCountChange = (value: number | '') => {
    const formatValue = value ? Math.round(value) : null
    onChange(value ? parse({ [unit]: formatValue }) : null, unit)
  }

  const onUnitChange = (unit: UnitType) => {
    onChange(count ? parse({ [unit]: count }) : null, unit)
  }

  const unitOptions = useMemo(() => {
    const defaultOptions = [
      { value: 'minutes', label: t('common:timeUnit.minutes') },
      { value: 'hours', label: t('common:timeUnit.hours') },
      { value: 'days', label: t('common:timeUnit.days') },
      { value: 'weeks', label: t('common:timeUnit.weeks') },
    ]
    return defaultOptions.filter((item: any) => supportUnits.includes(item.value))
  }, [supportUnits, t])

  return (
    <>
      <NumberInput
        size="xs"
        value={count}
        min={1}
        precision={0}
        hideControls
        onChange={onCountChange}
        classNames={{ wrapper: '!w-[40px]' }}
      />
      <Select size="xs" value={unit} classNames={{ wrapper: '!w-[64px]' }} onChange={onUnitChange} data={unitOptions} />
    </>
  )
}

export default DurationInput
