import React, { CSSProperties, ReactNode } from 'react'
import { Box, Group, ScrollArea, Stack, Sx, Text } from '@mantine/core'
import { get } from 'lodash-es'
import TableHeaderCell from 'components/ui/TableHeaderCell'

export type RowData = Record<string, any>
export type Column = {
  title?: ReactNode
  dataPath?: string
  flex?: number
  width?: number | string
  minWidth?: number
  maxWidth?: number
  cellStyle?: CSSProperties
  headerCellStyle?: CSSProperties
  cellRenderer?: (data: RowData, column: Column) => ReactNode
}
export type Props = {
  columns: Column[]
  data: RowData[]
  className?: string
  scrollbarOffset?: number
  footer?: ReactNode
  rowStyle?: (data: RowData) => Sx
  headerRowStyle?: Sx
  onRowClick?: (data: RowData) => void
}

const ScrollableTable = ({
  columns,
  data,
  rowStyle,
  headerRowStyle,
  scrollbarOffset = 24,
  footer,
  className,
  onRowClick,
}: Props) => {
  const headerStyles = { paddingRight: scrollbarOffset }
  const scrollAreaStyles = {
    viewport: {
      paddingRight: scrollbarOffset,
      paddingBottom: 0,
    },
  }

  return (
    <Stack h="100%" spacing={0} className={className}>
      <header style={headerStyles}>
        <Group position="apart" sx={headerRowStyle} noWrap>
          {columns.map((column) => {
            const { dataPath, minWidth, maxWidth, width, flex, cellStyle, headerCellStyle } = column
            return (
              <Box
                key={`header-cell-${dataPath}`}
                style={{ minWidth, maxWidth, width, flex, ...cellStyle, ...headerCellStyle }}
              >
                <TableHeaderCell>{column.title}</TableHeaderCell>
              </Box>
            )
          })}
        </Group>
      </header>
      <main className="flex-1 relative">
        <Box className="absolute" right={0} left={0} top={0} bottom={0}>
          <ScrollArea h="100%" type="scroll" styles={scrollAreaStyles} offsetScrollbars>
            <Stack spacing="lg">
              {data.map((dataItem, index) => {
                return (
                  <Group
                    noWrap
                    key={index}
                    position="apart"
                    sx={rowStyle?.(dataItem)}
                    onClick={() => {
                      onRowClick?.(dataItem)
                    }}
                  >
                    {columns.map((column) => {
                      const { dataPath, minWidth, maxWidth, width, flex, cellStyle, cellRenderer } = column
                      return (
                        <Box key={`body-cell-${dataPath}`} style={{ minWidth, maxWidth, width, flex, ...cellStyle }}>
                          <Text lineClamp={1}>
                            {!!cellRenderer ? cellRenderer(dataItem, column) : get(dataItem, dataPath ?? '')}
                          </Text>
                        </Box>
                      )
                    })}
                  </Group>
                )
              })}
            </Stack>
            {footer}
          </ScrollArea>
        </Box>
      </main>
    </Stack>
  )
}

export default ScrollableTable
