import React from 'react'
import { Center, Loader } from '@mantine/core'

const Loading = () => {
  return (
    <Center h="40vh">
      <Loader />
    </Center>
  )
}

export default Loading
