export * from './routerPath'
export * from './pageSize'
export * from './csatImages'
export * from './channelImg'
export * from './featureToggles'
export * from './channels'
export * from './eventBus'
export * from './form'
export * from './regex'
export * from './date'
export * from './modalConfig'

export const splitCharCode = 'œ'
export const NEXT_FIELD = 'nextField'
export const brandingUrl = 'https://howxm.com'

export const accountNameMaxlength = 20
export const nicknameMaxlength = 20
export const appNameMaxlength = 20

export const onboardingBreakpoints = {
  sm: 1200,
  md: 1440,
  lg: 1680,
  xl: 1920,
}

export const containerMaxSize = 1600
