import { ComponentProps } from 'react'
import { CampaignDistributionChannel } from 'types/domain'
import { Icon } from 'components/ui'

type IconNames = ComponentProps<typeof Icon>['name']
export const channelIconMap: Record<string, IconNames> = {
  [CampaignDistributionChannel.Link]: 'platformLink',
  [CampaignDistributionChannel.Web]: 'platformWeb',
  [CampaignDistributionChannel.IosSwift]: 'platformIos',
  [CampaignDistributionChannel.Android]: 'platformAndroid',
  [CampaignDistributionChannel.WechatMiniapp]: 'platformWechat',
}
