import data from '@emoji-mart/data'
import Picker from '@emoji-mart/react'
import { ActionIcon, Popover, Tooltip } from '@mantine/core'
import { FloatingPosition } from '@mantine/core/lib/Floating'
import i18n from '@emoji-mart/data/i18n/zh.json'
import { useEffect } from 'react'
import useTranslation from 'next-translate/useTranslation'

export type EmojiPickerProps = {
  onEmojiSelect: (emoji: string) => void
  iconSize?: string | number
  tooltipPosition?: FloatingPosition
}

export default function EmojiPicker({ onEmojiSelect, iconSize, tooltipPosition }: EmojiPickerProps) {
  const { t } = useTranslation('componentCampaignBuilder')

  const handleEmojiSelect = (emojiObject: any) => {
    onEmojiSelect(emojiObject.native as string)
  }
  useEffect(() => {
    i18n.search = t('emojiPicker.search')
  }, [t])

  return (
    <Popover withinPortal>
      <Tooltip mr={50} label={t('emojiPicker.pick')} position={tooltipPosition}>
        <Popover.Target>
          <ActionIcon size={iconSize}>😊</ActionIcon>
        </Popover.Target>
      </Tooltip>
      <Popover.Dropdown p={0}>
        <Picker
          data={data}
          locale="zh"
          onEmojiSelect={handleEmojiSelect}
          navPosition="top"
          previewPosition="none"
          emojiButtonSize={28}
          emojiSize={22}
          skinTonePosition="none"
          i18n={i18n}
        />
      </Popover.Dropdown>
    </Popover>
  )
}
