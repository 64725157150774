import { UrlObject } from '.'
import { Protocol } from 'config/constants/link'

export function parse(value: string) {
  const protocol = Object.values(Protocol).find((p) => value.startsWith(p)) || Protocol.HTTPS
  return {
    protocol,
    other: value.replace(protocol, ''),
  }
}

export function stringify(value: UrlObject) {
  return value.protocol + value.other
}

export function isPrefixWithProtocol(value: string) {
  return Object.values(Protocol).some((p) => value.startsWith(p))
}
