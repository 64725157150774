import {
  ChangeEventHandler,
  CSSProperties,
  FocusEventHandler,
  KeyboardEvent,
  KeyboardEventHandler,
  useRef,
  useState,
} from 'react'
import { Input, TextInputProps, useMantineTheme } from '@mantine/core'
import cls from 'classnames'
import styles from './index.module.scss'
import { isEnter } from 'utils/keyboardUtils'
import { useInputCompositionProps } from 'hooks'
import { getBackgroundColor, getBorderColor } from 'config/themeOverride/getColor'
import EmojiPicker, { EmojiPickerProps } from '../EmojiPicker'
import useInputInsert from 'components/ui/hooks/useInputInsert'

type Props = TextInputProps & {
  value?: string
  minWidth?: number
  maxWidth?: number
  width?: number
  height?: number
  placeholder?: string
  maxLength?: number
  style?: CSSProperties
  autoFocus?: boolean
  autoRestore?: boolean
  fullWidth?: boolean
  enterBlur?: boolean
  className?: string
  restoreText?: string
  inputStyle?: CSSProperties
  rightSectionStyle?: CSSProperties
  onChange?: (value: string) => void
  onFocus?: FocusEventHandler<HTMLInputElement>
  onBlur?: FocusEventHandler<HTMLInputElement>
  onKeyDown?: KeyboardEventHandler<HTMLInputElement>
  showEmoji?: boolean
  emojiPickerProps?: EmojiPickerProps
}

const InlineInput = ({
  value,
  maxWidth = 300,
  minWidth = 100,
  width,
  height,
  maxLength,
  placeholder,
  autoFocus = false,
  autoRestore = false,
  fullWidth = false,
  enterBlur = true,
  restoreText,
  style,
  inputStyle,
  rightSectionStyle,
  className = '',
  onChange,
  onFocus,
  onBlur,
  onKeyDown,
  showEmoji,
  emojiPickerProps,
  ...rest
}: Props) => {
  const [previousValue, setPreviousValue] = useState<string>('')
  const shouldRestore = autoRestore && value?.length === 0
  const {
    inCompositionRef,
    handleOnChange: propHandleOnChange,
    handleComposition,
  } = useInputCompositionProps(maxLength, onChange)
  const theme = useMantineTheme()
  const inputRef = useRef<HTMLInputElement>(null)
  const { insert } = useInputInsert({ ref: inputRef })

  const handleOnChange: ChangeEventHandler<HTMLInputElement> = (ev) => {
    let value = ev.target.value
    inputRef.current!.value = value
    propHandleOnChange(value)
  }

  const handleOnFocus: FocusEventHandler<HTMLInputElement> = (ev) => {
    onFocus?.(ev)
    if (autoRestore) {
      setPreviousValue(ev.target.value)
    }
  }
  const handleOnBlur: FocusEventHandler<HTMLInputElement> = (ev) => {
    onBlur?.(ev)
    if (autoRestore && ev.target.value.trim().length === 0) {
      onChange?.(restoreText ?? previousValue)
    }
  }
  const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = (ev) => {
    handleKeyDownWithComposition(ev)
    handleEnterBlur(ev)
  }

  const handleKeyDownWithComposition = (e: KeyboardEvent<HTMLInputElement>) => {
    // When there inComposition exists, enter does not trigger
    if (!(isEnter(e) && inCompositionRef.current)) {
      onKeyDown?.(e)
    }
  }

  const handleEnterBlur = (e: KeyboardEvent<HTMLInputElement>) => {
    if (enterBlur && isEnter(e) && !inCompositionRef.current) {
      ;(document.activeElement as HTMLElement)?.blur()
      e.preventDefault()
    }
  }
  const handleEmojiSelect = (emoji: string) => {
    insert(emoji)
    propHandleOnChange(inputRef.current?.value ?? '')
  }

  const inlineStyles = {
    minWidth,
    maxWidth: fullWidth ? '100%' : maxWidth,
    width: fullWidth ? '100%' : width,
    height,
    ...style,
  }

  return (
    <div className={cls(styles.wrapper, className)}>
      {!fullWidth && (
        <span className={styles.substitute} style={inlineStyles}>
          {value?.length === 0 && '&nbsp;'}
          {value}
        </span>
      )}
      <Input
        ref={inputRef}
        className={styles.input}
        pos={fullWidth ? 'static' : 'absolute'}
        style={inlineStyles}
        // @ts-ignore
        styles={{
          input: {
            '&:hover': {
              borderColor: getBorderColor('gray.3', theme),
              backgroundColor: getBackgroundColor('white', theme),
            },
            '&:focus': {
              borderColor: getBorderColor('brandBlue.7', theme),
              backgroundColor: getBackgroundColor('white', theme),
            },
            ...inputStyle,
          },
          rightSection: rightSectionStyle,
        }}
        value={value ?? ''}
        placeholder={shouldRestore ? previousValue : placeholder}
        autoFocus={autoFocus}
        onChange={handleOnChange}
        onFocus={handleOnFocus}
        onBlur={handleOnBlur}
        onKeyDown={handleKeyDown}
        onCompositionStart={handleComposition}
        onCompositionUpdate={handleComposition}
        onCompositionEnd={handleComposition}
        rightSection={showEmoji && <EmojiPicker onEmojiSelect={handleEmojiSelect} {...emojiPickerProps} />}
        {...rest}
      />
    </div>
  )
}

export default InlineInput
