import { Box, Button, HoverCard } from '@mantine/core'
import React, { forwardRef } from 'react'
import { IconRobot } from '@tabler/icons-react'
import { BoxProps } from '@mantine/core/lib/Box/Box'

interface Props extends BoxProps {
  label: string
  disabled?: boolean
  disabledTip?: React.ReactNode
  loading?: boolean
  onClick?: () => void
}

const AIButton = forwardRef<HTMLDivElement, Props>(
  ({ label, loading, disabled, disabledTip, onClick, ...rest }: Props, ref) => {
    return (
      <HoverCard disabled={!disabled} withArrow withinPortal>
        <HoverCard.Target>
          <Box pos="relative" w={100} h={36} ref={ref} {...rest}>
            <Button
              style={{ background: 'linear-gradient(to bottom right, #f6cf01, #ec4255, #2f54eb)' }}
              disabled={disabled}
              loading={loading}
              leftIcon={<IconRobot size={18} />}
              onClick={onClick}
              w="100%"
            >
              {label}
            </Button>
          </Box>
        </HoverCard.Target>
        <HoverCard.Dropdown>{disabledTip} </HoverCard.Dropdown>
      </HoverCard>
    )
  }
)

export default AIButton
