import { clearAllCookie, readString, write } from 'utils/cookieUtils'
import { SYSTEM_TOKEN_KEY, UID } from 'config/constants/cookie'

export interface AppContext {
  getSystemToken(): string | undefined

  setSystemToken(token: string): void

  getUid(): string | undefined

  setUid(id: string): void

  reset(): void
}

const appContext: AppContext = {
  getSystemToken() {
    return readString(SYSTEM_TOKEN_KEY)
  },
  setSystemToken(token: string) {
    write({ name: SYSTEM_TOKEN_KEY, value: token!, days: 60, domain: process.env.NEXT_PUBLIC_COOKIE_ALL_DOMAIN })
  },
  getUid() {
    return readString(UID)
  },
  setUid(id: string) {
    write({ name: UID, value: id!, days: 60, domain: process.env.NEXT_PUBLIC_COOKIE_ALL_DOMAIN })
  },
  reset() {
    clearAllCookie()
  },
}

export default appContext
