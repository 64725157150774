import { isEmpty } from 'lodash-es'
import { Translate } from 'next-translate'

const runes = require('runes')

export function isMobileOrEmail(value: string) {
  return isMobile(value) || isEmail(value)
}

export function isMobile(value: string | number) {
  return /^[1]([3-9])\d{9}$/.test(value.toString())
}

export function isEmail(value: string) {
  return /^[a-zA-Z0-9_+&*-]+(?:\.[a-zA-Z0-9_+&*-]+)*@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,7}$/.test(value)
}

export function convertToNonEmptyArray(value?: string) {
  return value
    ? value
        .replaceAll('\n', '')
        .split(',')
        .map((v) => v.trim())
        .filter(Boolean)
    : []
}

export function truncate(str: string, len: number) {
  return runes(str).length > len ? runes.substr(str, 0, len) + '...' : str
}

export function getRangeLabel(value: any[], t: Translate) {
  return isEmpty(value) ? '' : t('common:rangeLabel', { min: value[0], max: value?.[1] })
}

export function isBlank(value: string | undefined | null) {
  return !value || /^\s*$/.test(value)
}

//计算字符串长度，其中ascii码值是32-126的字符长度计为1，其他均为2
export function length(str: string) {
  let len = 0
  for (let i = 0; i < str.length; i++) {
    if (isEnCharacter(str.charCodeAt(i))) {
      len += 1
    } else {
      len += 2
    }
  }
  return len
}

function isEnCharacter(char: number) {
  return char >= 32 && char <= 126
}

//按照 ascii码值是32-126的字符长度计为1，其他均为2 的方式来进行字符串substring的截取
export function substring(str: string, offset: number | undefined) {
  if (offset == undefined || offset >= length(str)) {
    return str
  }
  let len = 0
  let result = ''
  for (let i = 0; i < str.length; i++) {
    result += str[i]
    if (isEnCharacter(str.charCodeAt(i))) {
      len += 1
    } else {
      len += 2
    }
    if (len >= offset) {
      return result
    }
  }
  return result
}
