import { Container, Flex, Text, useMantineTheme } from '@mantine/core'
import React, { ComponentProps, FC, PropsWithChildren } from 'react'
import { Icon } from 'components/ui'
import { getTextColor, getBackgroundColor } from 'config/themeOverride/getColor'

type Props = {
  className?: string
  textProps?: ComponentProps<typeof Text>
}

const HelpDocBlock: FC<PropsWithChildren<Props>> = ({ className, children, textProps }) => {
  const theme = useMantineTheme()
  return (
    <Container py="md" className={className + ' rounded-[4px]'} bg={getBackgroundColor('gray.0', theme)} mt="md">
      <Flex>
        <Icon name="infoCircle" />
        <Text className="flex-1" ml="sm" mt={-1} size="xs" color={getTextColor('gray.7', theme)} {...(textProps || {})}>
          {children}
        </Text>
      </Flex>
    </Container>
  )
}

export default HelpDocBlock
