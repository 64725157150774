import React, { FC, PropsWithChildren } from 'react'
import { Container, createStyles } from '@mantine/core'
import { getBackgroundColor } from 'config/themeOverride/getColor'
import useDarkMode from 'components/ui/Chart/useDarkMode'

type Props = {
  className?: string
}

const ContainerWithDotBg: FC<PropsWithChildren<Props>> = ({ className, children }) => {
  const darkMode = useDarkMode()
  const { classes, cx } = createStyles((theme) => ({
    container: {
      background: `radial-gradient(${darkMode ? '#d9d9d926' : '#e8ebf1ed'} .9px, ${getBackgroundColor(
        'gray.0',
        theme,
        'dark.5'
      )} .5px)`,

      backgroundSize: '10px 10px',
    },
  }))()

  return <Container className={cx(classes.container, className)}>{children}</Container>
}

export default ContainerWithDotBg
