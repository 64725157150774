import React, { PropsWithChildren } from 'react'
import { Paper } from '@mantine/core'
import { getBorderColor } from 'config/themeOverride/getColor'
import { PaperProps } from '@mantine/core/lib/Paper/Paper'

interface Props extends PaperProps {
  width?: number | string
  height?: number | string
  minHeight?: number
  noBorder?: boolean
}

const SessionPaper = ({
  children,
  width,
  height,
  noBorder,
  minHeight = 550,
  ...styleProps
}: PropsWithChildren<Props>) => {
  return (
    <Paper
      radius="md"
      sx={(theme) => ({
        maxWidth: '100%',
        width: width ?? 440,
        height: height ?? 550,
        minHeight,
        overflowY: 'auto',
        padding: '40px 40px 24px',
        [`@media (max-width: 488px)`]: {
          width: 'calc(100vw - 32px)',
          padding: '24px 16px',
          marginLeft: 'auto',
          marginRight: 'auto',
        },
        ...(noBorder
          ? {}
          : {
              border: `1px solid ${getBorderColor('gray.1', theme)}`,
              boxShadow:
                '0 1px 3px rgba(0, 0, 0, 0.05),rgba(0, 0, 0, 0.05) 0px 20px 25px -5px,rgba(0, 0, 0, 0.04) 0px 10px 10px -5px',
            }),
      })}
      {...styleProps}
    >
      {children}
    </Paper>
  )
}

export default SessionPaper
