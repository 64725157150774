import GTInit from './gt4'

export async function initGeetest(
  captchaId: string,
  onReady: (captchaObj: any) => void,
  onSuccess: (captchaObj: any) => void
) {
  GTInit()

  // @ts-ignore
  initGeetest4(
    {
      captchaId: captchaId,
      btnWidth: '100%',
      btnHeight: '45px',
      product: 'bind',
    },
    (captcha: any) => {
      captcha.onReady(() => {
        onReady(captcha)
      })
      captcha.onSuccess(() => {
        onSuccess(captcha)
      })
    }
  )
}

export function getGeetestResult(captcha: any) {
  const geetestResult = captcha.getValidate()
  return {
    lotNumber: geetestResult.lot_number,
    captchaOutput: geetestResult.captcha_output,
    passToken: geetestResult.pass_token,
    genTime: geetestResult.gen_time,
    captchaId: geetestResult.captcha_id,
  }
}
