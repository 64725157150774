export function getAfter(page: number, pageSize: number) {
  return { after: page === 1 ? undefined : encodeCursor((page - 1) * pageSize) }
}

export function encodeCursor(cursor: number | string) {
  return window.btoa(String(cursor))
}

export const decodeCursor = (cursor: string | undefined): number => {
  return parseInt(window.atob(cursor ?? '0'), 10)
}
