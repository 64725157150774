import { InMemoryCache } from '@apollo/client'
import generatedIntrospection from 'types/domain'

export default new InMemoryCache({
  possibleTypes: {
    ...generatedIntrospection.possibleTypes,
  },
  typePolicies: {
    CampaignConnection: {
      merge: true,
    },
    UserCampaignSetting: {
      merge: true,
    },
    FeedbackStats: {
      merge: true,
    },
    CampaignWidget: {
      merge: true,
    },
    AccountSetting: {
      merge: true,
    },
    Product: {
      keyFields: ['code'],
    },
    CampaignTemplate: {
      keyFields: ['code'],
    },
    Toggle: {
      keyFields: ['name'],
    },
    FormField: {
      keyFields: ['formId', 'fieldCode'],
    },
    CampaignOverviewStats: {
      keyFields: ['campaignId'],
    },
    FormFieldStats: {
      keyFields: ['formId', 'fieldCode'],
    },
    ApplicationMemberRole: {
      keyFields: ['applicationId', 'member', ['id']],
    },
    // 这里结果是跟上面写法等效的，但是下面可以扩展更多的逻辑
    // Application: {
    //   fields: {
    //     campaigns: {
    //       merge(existing, incoming, { mergeObjects }) {
    //         return mergeObjects(existing, incoming)
    //       },
    //     },
    //   },
    // },
  },
})
