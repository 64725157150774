// Form meta fields
export const SERIAL_NUMBER = 'serialNumber'
export const COMMENTS_COUNT = 'commentsCount'
export const HAS_REPLY = 'hasReply'
export const CREATED_AT = 'createdAt'
export const FILLING_DURATION = 'fillingDuration'
export const OS = 'os'
export const IP = 'ip'
export const REGION = 'region'
export const BROWSER = 'browser'
export const PLATFORM = 'platform'
export const CUSTOMER_UID = 'customer_uid'
export const CUSTOMER_NAME = 'customer_name'
export const CUSTOMER_MOBILE = 'customer_mobile'
export const CUSTOMER_EMAIL = 'customer_email'
export const SUBMITTED_ON = 'submitted_on'
export const REFERER = 'referer'
