import React, { forwardRef, ForwardRefRenderFunction, useCallback, useEffect, useState } from 'react'
import useTranslation from 'next-translate/useTranslation'
import { TextInput } from '@mantine/core'
import { isEmpty } from 'lodash-es'

import { isUrl } from 'utils'

interface Props {
  value?: string
  placeholder?: string
  onChange?: (value: string, error?: string) => void
  allowBlank?: boolean
  validateFirst?: boolean
  className?: string
  checkUrl?: boolean
}

const UrlInput: ForwardRefRenderFunction<HTMLInputElement, Props> = (props: Props, ref) => {
  const { allowBlank, onChange, value, validateFirst, checkUrl = true, ...rest } = props
  const { t } = useTranslation('common')
  const [error, setError] = useState<string | undefined>()
  const [inputValue, setInputValue] = useState<string>(value || '')

  useEffect(() => {
    setInputValue(value || '')
  }, [value])

  const getUrlError = useCallback(
    (url: string | undefined, allowBlank?: boolean) => {
      if (isEmpty(url)) {
        if (!allowBlank) {
          return t('message.urlRequired')
        }
      } else {
        if (checkUrl && !isUrl(url!)) {
          return t('message.urlInvalid')
        }
      }
    },
    [checkUrl, t]
  )

  useEffect(() => {
    validateFirst && setError(getUrlError(value?.trim(), allowBlank))
  }, [allowBlank, getUrlError, validateFirst, value])

  const handleChange = (value: string) => {
    const url = value.trim()
    const err = getUrlError(url, allowBlank)
    setError(err)
    onChange?.(url, err)
    setInputValue(url)
  }

  const clearError = () => setError(undefined)

  return (
    <TextInput
      ref={ref}
      value={inputValue}
      onFocus={clearError}
      onChange={(e) => handleChange(e.target.value)}
      error={error}
      {...rest}
    />
  )
}

export default forwardRef(UrlInput)
