import { DATE_FORMAT } from 'config/constants'
import {
  addDays,
  differenceInDays,
  eachMonthOfInterval,
  eachWeekOfInterval,
  format,
  isThisYear,
  parseISO,
  startOfMonth,
  startOfWeek,
} from 'date-fns'
import dayjs from 'dayjs'
import { cloneDeep } from 'lodash-es'
import { Translate } from 'next-translate'

export function formatISOTime(value: string) {
  const TIME_FORMAT = 'yyyy-MM-dd HH:mm:ss'
  return value ? format(parseISO(value), TIME_FORMAT) : value
}

export function formatISOTimeOrNull(value: string) {
  try {
    return formatISOTime(value)
  } catch (e) {
    return null
  }
}

export function formatISODate(value: string) {
  return value ? format(parseISO(value), DATE_FORMAT) : value
}

export function formatISODATE(value: Date) {
  return format(value, DATE_FORMAT)
}

export function formatSlashedISODATE(value: string) {
  const DATE_FORMAT = 'yyyy/MM/dd'
  return value ? format(parseISO(value), DATE_FORMAT) : value
}

export function formatRangeDates(value: [Date | null, Date | null]) {
  const DATE_FORMAT = 'yyyy/MM/dd'
  const [startDate, endDate] = value
  return startDate || endDate
    ? (startDate ? format(startDate, DATE_FORMAT) : '') + ' - ' + (endDate ? format(endDate, DATE_FORMAT) : '')
    : null
}

export function formatISOOnlyTime(value: string) {
  const TIME_FORMAT = 'HH:mm:ss'
  return value ? format(parseISO(value), TIME_FORMAT) : value
}

export function formatISOMonthAndTime(value: string) {
  const TIME_FORMAT = 'MM-dd HH:mm'
  return value ? format(parseISO(value), TIME_FORMAT) : value
}

export function formatISOMinuteTime(value: string) {
  const FORMAT = 'yyyy-MM-dd HH:mm'
  return value ? format(parseISO(value), FORMAT) : value
}

export function formatISOMonthAndDay(value: Date) {
  const FORMAT = 'MM/dd'
  return value ? format(value, FORMAT) : value
}

export function formatYearAndMonth(value: Date) {
  const FORMAT = 'yyyy/MM'
  return value ? format(value, FORMAT) : value
}

export function formatWeekRange(startOfWeek: Date) {
  if (!startOfWeek) {
    return startOfWeek
  }
  const endOfWeek = addDays(startOfWeek, 6)
  return formatRangeDates([startOfWeek, endOfWeek])
}

export function formatISODateWithDatePattern(value: string, datePattern: string) {
  return value ? format(parseISO(value), datePattern) : value
}

export function differenceDays(value: string) {
  return value ? differenceInDays(parseISO(value), new Date()) : 0
}

export function formatTimeByThisYear(value: string) {
  return isThisYear(parseISO(value)) ? formatISOMonthAndTime(value) : formatISOMinuteTime(value)
}

export function getLastDayDuration(days: number) {
  let startDate = dayjs()
    .subtract(days - 1, 'd')
    .toDate()
  return getDateDuration(startDate, new Date())
}

export function getDateDuration(startDate: Date, endDate: Date) {
  let result = []
  while (startDate <= endDate) {
    result.push(cloneDeep(startDate))
    startDate.setDate(startDate.getDate() + 1)
  }
  return result
}

export function getWeekDuration(startDate: Date, endDate: Date) {
  const weeks = eachWeekOfInterval({ start: startDate, end: endDate })
  return weeks.map((weekStartDate) => startOfWeek(weekStartDate)).map((weekStartDate) => addDays(weekStartDate, 1))
}

export function getMonthDuration(startDate: Date, endDate: Date) {
  const months = eachMonthOfInterval({ start: startDate, end: endDate })
  return months.map((monthStartDate) => startOfMonth(monthStartDate))
}

export function getDayOfWeek(date: Date, t: Translate) {
  return t(`common:dayOfWeek.${date.getDay()}`)
}

export function monthDayWithWeekday(date: Date, t: Translate) {
  return `${formatISOMonthAndDay(date)}（${getDayOfWeek(date, t)}）`
}

export function endOfDate(date: Date) {
  return dayjs(date).endOf('day').toDate()
}
